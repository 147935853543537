import { Component, OnInit } from '@angular/core';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/provider/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegEx, ValidationMessage } from 'src/app/config/validation-message';
@Component({
  selector: 'app-edit-arm',
  templateUrl: './edit-arm.component.html',
  styleUrls: ['./edit-arm.component.scss']
})
export class EditArmComponent implements OnInit {

  addArmForm: FormGroup
  imageUrl
  validationMessage = ValidationMessage; //global validation code
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  categoryId:any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private activatedroute: ActivatedRoute, private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.categoryId = res.categoryId;
    })
    this.addArmFormValidation();
    this.viewArm()
  }
  addArmFormValidation() {
    this.addArmForm = new FormGroup({
      'arm': new FormControl('', [Validators.required, Validators.pattern(RegEx.name)]),
      'image': new FormControl('')
    })
  }

  /**
   * @call validation 
   */
  get colorName() { return this.addArmForm.get('colorName') }
  get arm() { return this.addArmForm.get('arm') }
 
 

  editArm() {
    let apiReqUrl : any =`${ApiEndPoint.editArm + '?_id=' + this.categoryId}`
    let data = {
           'armName': this.addArmForm.value.arm,
          'rawImage' : this.imageUrl
    }
    this.commonService.showSpinner();
    this.commonService.putApi(apiReqUrl, data, 1).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.responseCode == 200) {
        this.commonService.successToast(res.responseMessage);
        this.router.navigate(['/arm-management/list-arm'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  //view swatches
  // get category detail by id
  viewArm() {
    let apiReqUrl: any = `${ApiEndPoint.viewArm + '?_id=' + this.categoryId}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.addArmForm.patchValue({
          'arm' : res?.result?.armName,
        })
        this.imageUrl = res?.result?.rawImage
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  colorNameArr : any = []
  showImageFetch(name,image,mobImage, i) {
     this.colorArray[i] = {
      'colorId': name,
      'image': {webImage:image, mobileImage : mobImage}
    }
    console.log(this.showImageField[i]);
    this.colorNameArr[i] = name
    this.showImageField[i] = true;
    console.log(this.showImageField[i]);

  }



  uploadImg(event): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi(ApiEndPoint.uploadFile, fb, 1).subscribe((res: any) => {
      console.log(res);
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.imageUrl = {webImage : res['result']['webImage'], mobileImage : res['result']['mobileImage'] }
      } else {
        this.commonService.hideSpinner();
      }
    })
  }


  async uploadColorFunc(img, i) {
    var fb = new FormData();
    fb.append('file', img.target.files[0])
    this.commonService.showSpinner();
    let respose = await this.commonService.postFormDataApi(ApiEndPoint.uploadFile, fb, 1).toPromise()
    console.log(this.colorArray);
this.commonService.hideSpinner()
    this.colorArray[i] = {
      'colorId': this.colorArray[i]['colorId'],
      // 'image': respose['result']['webView']
      'image': {webImage : respose['result']['webImage'], mobileImage : respose['result']['mobileImage'] }

    }

  }
  colorFieldArray: any = [1]
  colorArray: any = []
  addColour() {
    this.colorFieldArray.push(1)
  }
  showImage(event, i) {

    this.colorArray[i] = {
      'colorId': event.target.value,
      'image': ''
    }
    console.log(this.showImageField[i]);

    this.showImageField[i] = true;
    console.log(this.showImageField[i]);

  }

}
