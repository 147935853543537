import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';
import { Router, NavigationEnd } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
declare var $: any

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  showSidebar: boolean
  currentUrl: any;
  profileImage: any;
  profileData: any;
  showLogo: boolean = false;
  constructor(public commonService: CommonService, private router: Router) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        // console.log(e.url)
        this.currentUrl = '/' + e.url.split('/')[1];
        if (this.currentUrl === '/' || this.currentUrl === '/login' || this.currentUrl == '/forgot-password') {
          if (localStorage.getItem('mmAdminToken')) {
            this.router.navigate(['dashboard'])
            this.showLogo = true;
          } else {
            this.showLogo = false;
          }
        }
      }
    });
  }

  showadmin(event) {
    // console.log('event', event);
    if (this.showLogo == false) {
      if ((document.getElementById("admin").style.width = "45px")) {
        document.getElementById("admin").style.display = "none";
        this.showLogo = true;
      }
    }
  }

  ngOnInit() {
    $('.btn-toggle,.close_panel').click(() => {
      $('body').toggleClass('toggle-wrapper');
    });
    this.commonService.loginStatus.subscribe((res: boolean) => console.log("status", this.showSidebar = res))
    if (localStorage.getItem('mmAdminToken')) {
      this.showSidebar = true;
      this.getProfile();
    }
    this.commonService.loginData.subscribe((res: any) => {
      console.log(res);
      if (res) { this.profileData = res }
    })
  }

  // get profile
  getProfile() {
    console.log("profile calll");

    this.commonService.showSpinner()
    this.commonService.getApi(ApiEndPoint.profile, 1).subscribe((res: any) => {
      console.log("sidebar profile response ==>", res);
      if (res.responseCode == 200) {
        this.profileData = res.result
        this.commonService.hideSpinner();
        // this.
        // this.commonService.successToast(res.responseMessage);

      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);

      }
    })
  }

  adminProfile() {
    this.router.navigate(['/setting/profile'])
  }

  logOutModal() {
    $('#logoutModal').modal('show')
  }

  logout() {
    $('#logoutModal').modal('hide')
    this.commonService.logout()
  }

}
