import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListDiamondComponent } from './list-diamond/list-diamond.component';
import { AddDiamondComponent } from './add-diamond/add-diamond.component';
import { EditDiamondComponent } from './edit-diamond/edit-diamond.component';
import { ViewDiamondComponent } from './view-diamond/view-diamond.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
// import { AddBodyComponent } from '../body-management/add-body/add-body.component';
// import { EditBodyComponent } from '../body-management/edit-body/edit-body.component';
// import { ListBodyComponent } from '../body-management/list-body/list-body.component';
// import { ViewBodyComponent } from '../body-management/view-body/view-body.component';

const routes: Routes = [
  { path: '', component: ListDiamondComponent },
  { path: 'list-diamond', component: ListDiamondComponent },
  { path: 'add-diamond', component: AddDiamondComponent },
  { path: 'edit-diamond', component: EditDiamondComponent },
  { path: 'view-diamond', component: ViewDiamondComponent },
]


@NgModule({
  declarations: [ListDiamondComponent, AddDiamondComponent, EditDiamondComponent, ViewDiamondComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ]
})
export class DiamondManagementModule { }
