<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Arm Management / View</h1>
        </div>
        <div class="content-section">
            <div class="order-view mrgn-0-auto setting-page">
                <div class="mb40">
                    <div class="add-store-block input-style view-label-align  max-WT-700 ">

                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright ">Arm Name <span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <p style="padding: 0px 5px!important;">
                                        {{armListArray?.armName || '--'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright ">Arm Image <span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <p style="padding: 0px 5px!important;">
                                        <img src="{{armListArray?.rawImage?.webImage}}" alt="">
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright">Created At <span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">
                                        {{(armListArray?.createdAt | date:'medium' ) || '--'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme"
                            routerLink="/arm-management/list-arm">Back</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>