import { flatten } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { element } from 'protractor';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { ValidationMessage } from 'src/app/config/validation-message';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-edit-vat',
  templateUrl: './edit-vat.component.html',
  styleUrls: ['./edit-vat.component.scss']
})
export class EditVatComponent implements OnInit {
  editTrunkForm: FormGroup
  vatNumber: any
  addTrunkForm: FormGroup
  validationMessage = ValidationMessage; //global validation code
  vatTax = [{ name: '', rates: '' }]
  trunkId: any
  nameOfCountry: any = ""

  constructor(public commonService: CommonService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.queryParams.subscribe((res) => {
      this.trunkId = res.trunkId;
    })
  }

  ngOnInit(): void {
    this.addCategoryFormValidation()
    this.addColorFormValidation()
    this.viewTrunk();
    this.countryList();
  }

  addCategoryFormValidation() {
    this.editTrunkForm = new FormGroup({
      'country': new FormControl(''),
      'vatStatus': new FormControl('')
    })
  }
  addColorFormValidation() {
    this.addTrunkForm = new FormGroup({
      'country': new FormControl('', [Validators.required]),
      'vStatus': new FormControl('', [Validators.required]),
      'vatNum': new FormControl('', [Validators.required]),
    })
  }

  get country() { return this.addTrunkForm.get('country') }
  get vStatus() { return this.addTrunkForm.get('vStatus') }
  get vatNum() { return this.addTrunkForm.get('vatNum') }


  setValidators(e) {
    this.addTrunkForm.get('vatNum').reset()
    this.addTrunkForm.get('vatNum').clearValidators();

    if (e == 'YES') {
      let a = this.addTrunkForm.get('vatNum')
      a.setValidators([Validators.required])
    }
    else {
      this.addTrunkForm.get('vatNum').clearValidators();
      this.addTrunkForm.get('vatNum').reset()
    }
    this.addTrunkForm.controls['vatNum'].updateValueAndValidity();
  }

  // vat functions
  vatFeild: string
  vatStatus(event) {
    this.vatFeild = event;
    this.setValidators(this.vatFeild)
  }

  addTax() {
    this.vatTax.push({ name: '', rates: '' })
  }

  removeVatFeildi(i) {
    this.vatTax.splice(i, 1)
  }


  listCountry: any
  countryList() {
    let url = ApiEndPoint.countryList;
    this.commonService.getApi(url, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.listCountry = res.result;
      }
    })
  }

  countryName(event) {
    this.nameOfCountry = event;
    console.log(this.nameOfCountry);

  }
  allData: any
  viewTrunk() {
    let apiReqUrl: any = `${ApiEndPoint.viewVat + '?_id=' + this.trunkId}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.allData = res.result
        this.vatTax = this.allData.vatRates
        this.vatNumber = this.allData.vatNumber
        this.vatFeild = this.allData.doYouNeedToChargeVat
        this.vatStatus(this.allData.doYouNeedToChargeVat)
        this.countryName(this.allData.country)
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, err => {
      this.commonService.hideSpinner();
    })
  }

  // onSubmit() {
  //   alert('SUCCESS!! :-)\n\n')
  // }

  addVat() {
    let url = ApiEndPoint.updateVat;
    let isValid = true
    this.vatTax.forEach((element) => {
      if (!element.name || !element.rates) {
        isValid = false
        return
      }
    })
    if (!isValid) {
      return
    }
    const data = {
      _id: this.trunkId,
      country: this.nameOfCountry,
      doYouNeedToChargeVat: this.vatFeild,
      vatNumber: this.vatNumber,
      vatRates: this.vatTax
    }
    this.commonService.showSpinner();
    this.commonService.postApi(url, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
        this.router.navigate(['/tax-shipping-management/vat-list']);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    }, err => {
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.responseMessage);
    })
  }

}
