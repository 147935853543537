import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'

import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
// import { NgOtpInputModule } from 'ng-otp-input';
// import { CKEditorModule } from 'ngx-ckeditor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { HeaderComponent } from './pages/sharing/header/header.component';
import { FooterComponent } from './pages/sharing/footer/footer.component';
import { SidebarComponent } from './pages/sharing/sidebar/sidebar.component';
import { HttpErrorInterceptor } from './interceptor/http-error.interceptor';
import { HttpResInterceptor } from './interceptor/http-res.interceptor';
import { CKEditorModule } from 'ngx-ckeditor';
import { ForgotComponent } from './pages/auth/forgot/forgot.component';
import { ResetComponent } from './pages/auth/reset/reset.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { DatePipe } from '@angular/common';
// import { PremiumColorManagementComponent } from './pages/premium-color-management/premium-color-management.component';
// import { ImageViewNamePipe } from './pipes/image-view-name.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ForgotComponent,
    ResetComponent,
    // PremiumColorManagementComponent,

    // ImageViewNamePipe,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      maxOpened: 1,
      preventDuplicates: true
    }),
    HttpClientModule, // Add this to the imports array

    NgxPaginationModule,
    CKEditorModule,
    NgOtpInputModule

  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpResInterceptor, multi: true }, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
