<aside [hidden]="!showSidebar" class="sidebar" style="z-index: 7">
    <div class="adminprofile">
        <a class="navbar-brand logo" style="width: 100%; display: inline-flex; justify-content: center;">
            <span class="sidebar-profile-image-sec">

                <img class="lg-logo" [src]="profileData?.profilePic || 'assets/img/profile-img.jpg'" alt="image"
                    style="cursor: pointer;height: 85px;width: 85px; border-radius: 50%;" (click)="adminProfile()">
            </span>
        </a>
        <div id="admin" class="profile-data-section" style="text-align: center; color: #73737245;">
            <label style="cursor: pointer; width: 70%; overflow: hidden; text-overflow: ellipsis;
            white-space: nowrap;" (click)="adminProfile()"> {{profileData?.firstName || '--'}}
                {{profileData?.lastName || '--'}}</label>
            <p style="cursor: pointer;" (click)="adminProfile()">{{profileData?.email || '--' }}</p>
        </div>
    </div>

    <div class="sidebar-scroller">
        <div id="accordion" class="sidebar-menu">
            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/dashboard' ? 'active' : ''"
                style="border-top: 1px solid #2b2b2b;">
                <div class="card-header clearfix" [routerLink]="['/dashboard']" id="blank" data-toggle="collapse"
                    data-target="#blankId" aria-expanded="false" aria-controls="blank">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-dashboard" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Dashboard</span></a>
                </div>
            </div>

            <!-- user management -->
            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/user' ? 'active' : ''">
                <div class="card-header clearfix">
                    <a class="side_menu" id="" data-toggle="collapse" data-target="#userManagement"
                        aria-expanded="false" aria-controls="userManagement" [routerLink]="['/user/list-customer']">
                        <span class="side_menu_icon"><i class="fa fa-user" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">User Management</span>
                    </a>
                    <i class="fa fa-chevron-down righticon" aria-hidden="true" data-toggle="collapse"
                        data-target="#userManagement" aria-expanded="false" aria-controls="userManagement"
                        [routerLink]="['/user/list-customer']"></i>

                    <ul id="userManagement" class="collapse list-unstyled m-0" aria-labelledby="userManagement"
                        data-parent="#accordion">
                        <li [ngClass]="currentUrl === '/user/list-customer' ? 'active' : ''"
                            [routerLink]="['/user/list-customer']"><a> <i class="fa fa-bullseye" aria-hidden="true"></i>
                                Consumer Management</a></li>
                        <li [ngClass]="currentUrl === '/user-list' ? 'active' : ''"
                            [routerLink]="['/user/list-retailer']"><a> <i class="fa fa-bullseye" aria-hidden="true"></i>
                                Retailer Management</a></li>
                        <li [ngClass]="currentUrl === '/user-list' ? 'active' : ''"
                            [routerLink]="['/user/list-influencer']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Influencer Management</a></li>
                    </ul>
                </div>
            </div>

            <!-- <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/user/list-customer' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/user/list-customer']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-users" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Customer Management</span></a>
                </div>
            </div> -->

            <!-- <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/user-list' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/user/list-retailer']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-users" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Retailer Management</span></a>
                </div>
            </div> -->
            <!-- <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/user-list' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/user/list-influencer']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-users" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Influencer Management</span></a>
                </div>
            </div> -->

            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/sub-admin' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/sub-admin/list-sub-admin']" id="headingThree"
                    data-toggle="collapse" data-target="#blankId" aria-expanded="false" aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">

                            <!-- <i class="fa fa-money" aria-hidden="true"></i> -->
                            <i class="fa fa-users" aria-hidden="true"></i>

                        </span><span class="side_page_name head_color">Sub Admin Management</span></a>
                </div>
            </div>

            <!-- category management -->
            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/category' ? 'active' : ''">
                <div class="card-header clearfix">
                    <a class="side_menu" id="" data-toggle="collapse" data-target="#categoryManagement"
                        aria-expanded="false" aria-controls="categoryManagement"
                        [routerLink]="['/category/list-category']">
                        <span class="side_menu_icon"><i class="fa fa-user" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Category Management</span>
                    </a>
                    <i class="fa fa-chevron-down righticon" aria-hidden="true" data-toggle="collapse"
                        data-target="#categoryManagement" aria-expanded="false" aria-controls="categoryManagement"
                        [routerLink]="['/category/list-category']"></i>

                    <ul id="categoryManagement" class="collapse list-unstyled m-0" aria-labelledby="categoryManagement"
                        data-parent="#accordion">
                        <li [ngClass]="currentUrl === '/category/list-category' ? 'active' : ''"
                            [routerLink]="['/category/list-category']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Category</a></li>
                        <li [ngClass]="currentUrl === '/sub-category/list-subcategory' ? 'active' : ''"
                            [routerLink]="['/sub-category/list-subcategory']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Sub Category</a></li>
                    </ul>
                </div>
            </div>

            <!-- <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/category/list-category' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/category/list-category']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-list-alt" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Category Management</span></a>
                </div>
            </div> -->

            <!-- <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/sub-category/list-subcategory' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/sub-category/list-subcategory']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-list-alt" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Sub Category Management</span></a>
                </div>
            </div> -->


            <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/product-category/list-product-category' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/product-category/list-product-category']"
                    id="headingThree" data-toggle="collapse" data-target="#blankId" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">

                            <!-- <i class="fa fa-money" aria-hidden="true"></i> -->
                            <i class="fa fa-users" aria-hidden="true"></i>

                        </span><span class="side_page_name head_color">Product Management</span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/collection-management/list-collection' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/collection-management/list-collection']"
                    id="headingThree" data-toggle="collapse" data-target="#blankId" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">

                            <!-- <i class="fa fa-money" aria-hidden="true"></i> -->
                            <i class="fa fa-users" aria-hidden="true"></i>

                        </span><span class="side_page_name head_color">Collection Category</span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/color/list-color' || currentUrl === '/silhouette/list-silhouette' || currentUrl === '/neckline/list-neckline'  || currentUrl === '/swatches/list-swatches' || currentUrl === '/embellishment/list-embellishment'  ? 'active' : ''">
                <div class="card-header clearfix">
                    <a class="side_menu" id="" data-toggle="collapse" data-target="#productManagement"
                        aria-expanded="false" aria-controls="productManagement"
                        [routerLink]="['/body-management/list-body']">
                        <span class="side_menu_icon"><i class="fa fa-product-hunt" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Product Master</span>
                    </a>
                    <i class="fa fa-chevron-down righticon" aria-hidden="true" data-toggle="collapse"
                        data-target="#productManagement" aria-expanded="false" aria-controls="productManagement"
                        [routerLink]="['/body-management/list-body']"></i>

                    <ul id="productManagement" class="collapse list-unstyled m-0" aria-labelledby="productManagement"
                        data-parent="#accordion">
                        <li [ngClass]="currentUrl === '/body-management/list-body' ? 'active' : ''"
                            [routerLink]="['/body-management/list-body']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Body Management</a></li>
                        <li [ngClass]="currentUrl === '/skirt-management/list-skirt' ? 'active' : ''"
                            [routerLink]="['/skirt-management/list-skirt']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Skirt Management</a></li>
                        <li [ngClass]="currentUrl === '/sketch-management/list-sketch' ? 'active' : ''"
                            [routerLink]="['/sketch-management/list-sketch']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Sketch Management</a></li>

                      

                        <li [ngClass]="currentUrl === '/embellishment/list-embellishment' ? 'active' : ''"
                            [routerLink]="['/embellishment/list-embellishment']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Embellishment</a>
                        </li>

                        <li [ngClass]="currentUrl === '/diamond-management/list-diamond' ? 'active' : ''"
                            [routerLink]="['/diamond-management/list-diamond']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Diamond Management</a>
                        </li>
                        <!-- <li [ngClass]="currentUrl === '/product/list-product' ? 'active' : ''"
                            [routerLink]="['/product/list-product']"><a>
                                <i class="fa fa-bullseye" aria-hidden="true"></i>
                                Product</a></li> -->

                        <!-- <li [ngClass]="currentUrl === '/collection-management/list-collection' ? 'active' : ''"
                            [routerLink]="['/collection-management/list-collection']">
                            <a>
                                <i class="fa fa-bullseye" aria-hidden="true"></i>
                                Collection Category Management</a>
                        </li> -->

                        <!-- <li [ngClass]="currentUrl === '/product-category/list-product-category' ? 'active' : ''"
                            [routerLink]="['/product-category/list-product-category']"><a>
                                <i class="fa fa-bullseye" aria-hidden="true"></i>
                                Product Category</a></li> -->

                        <!-- <li [ngClass]="currentUrl === '/neckline/list-neckline' ? 'active' : ''"
                            [routerLink]="['/neckline/list-neckline']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Neckline Management</a>
                        </li> -->

                        <!-- <li [ngClass]="currentUrl === '/silhouette/list-silhouette' ? 'active' : ''"
                            [routerLink]="['/silhouette/list-silhouette']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Silhouette Management</a>
                        </li> -->

                        <li [ngClass]="currentUrl === '/swatches/list-swatches' ? 'active' : ''"
                            [routerLink]="['/swatches/list-swatches']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Swatches Management</a>
                        </li>
                        <li [ngClass]="currentUrl === '/premium-color/list-premium-color' ? 'active' : ''"
                            [routerLink]="['/color/list-color']"><a> <i class="fa fa-bullseye" aria-hidden="true"></i>
                                Standard Color</a></li>
                        <li [ngClass]="currentUrl === '/premium-color/list-premium-color' ? 'active' : ''"
                            [routerLink]="['/premium-color/list-premium-color']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Premium Color</a></li>
                    </ul>
                </div>
            </div>

            <!-- product management -->
            <!-- <div class="menu-box toggle-menu"
                                                        [ngClass]="currentUrl === '/product/list-product' || currentUrl === '/color/list-color' || currentUrl === '/silhouette/list-silhouette' || currentUrl === '/neckline/list-neckline'  || currentUrl === '/swatches/list-swatches' || currentUrl === '/embellishment/list-embellishment'  ? 'active' : ''">
                                                        <div class="card-header clearfix">
                                                            <a class="side_menu" id="" data-toggle="collapse" data-target="#productManagement"
                                                                aria-expanded="false" aria-controls="productManagement"
                                                                [routerLink]="['/product/list-product']">
                                                                <span class="side_menu_icon"><i class="fa fa-product-hunt" aria-hidden="true"></i>
                                                                </span><span class="side_page_name head_color">Product Management</span>
                                                            </a>
                                                            <i class="fa fa-chevron-down righticon" aria-hidden="true" data-toggle="collapse"
                                                                data-target="#productManagement" aria-expanded="false" aria-controls="productManagement"
                                                                [routerLink]="['/product/list-product']"></i>
                                        
                                                            <ul id="productManagement" class="collapse list-unstyled m-0" aria-labelledby="productManagement"
                                                                data-parent="#accordion">
                                                                <li [ngClass]="currentUrl === '/product/list-product' ? 'active' : ''"
                                                                    [routerLink]="['/product/list-product']"><a>
                                                                        <i class="fa fa-bullseye" aria-hidden="true"></i>
                                                                        Product</a></li>
                                        
                                                                <li [ngClass]="currentUrl === '/collection-management/list-collection' ? 'active' : ''"
                                                                    [routerLink]="['/collection-management/list-collection']">
                                                                    <a>
                                                                        <i class="fa fa-bullseye" aria-hidden="true"></i>
                                                                        Product Collection Management</a>
                                                                </li>
                                        
                                                                <li [ngClass]="currentUrl === '/product-category/list-product-category' ? 'active' : ''"
                                                                    [routerLink]="['/product-category/list-product-category']"><a>
                                                                        <i class="fa fa-bullseye" aria-hidden="true"></i>
                                                                        Product Category</a></li>
                                        
                                                                <li [ngClass]="currentUrl === '/neckline/list-neckline' ? 'active' : ''"
                                                                    [routerLink]="['/neckline/list-neckline']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Neckline Management</a>
                                </li>
                                
                                <li [ngClass]="currentUrl === '/silhouette/list-silhouette' ? 'active' : ''"
                                    [routerLink]="['/silhouette/list-silhouette']"><a> <i class="fa fa-bullseye" aria-hidden="true"></i>
                                        Silhouette Management</a>
                                </li>
                                
                                <li [ngClass]="currentUrl === '/color/list-color' ? 'active' : ''" [routerLink]="['/color/list-color']"><a> <i
                                            class="fa fa-bullseye" aria-hidden="true"></i>
                                        Color Management</a></li>

                        <li [ngClass]="currentUrl === '/swatches/list-swatches' ? 'active' : ''" [routerLink]="['/swatches/list-swatches']"><a>
                                <i class="fa fa-bullseye" aria-hidden="true"></i>
                                Swatches Management</a>
                        </li>
                    </ul>
                    </div>
            </div> -->
            <!-- bjbnb -->

            <!-- <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/product/list-product' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/product/list-product']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-product-hunt" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Product Management </span></a>
                </div>
            </div> -->

            <!-- custom product management -->
            <!-- <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/custom-product' || currentUrl === '/body-management' || currentUrl === '/skirt-management' ? 'active' : ''">
                <div class="card-header clearfix">
                    <a class="side_menu" id="" data-toggle="collapse" data-target="#customProductManagement"
                        aria-expanded="false" aria-controls="customProductManagement"
                        [routerLink]="['/custom-product/list-custom-product']">
                        <span class="side_menu_icon"><i class="fa fa-user" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Custom Product Management</span>
                    </a>
                    <i class="fa fa-chevron-down righticon" aria-hidden="true" data-toggle="collapse"
                        data-target="#customProductManagement" aria-expanded="false"
                        aria-controls="customProductManagement"
                        [routerLink]="['/custom-product/list-custom-product']"></i>

                    <ul id="customProductManagement" class="collapse list-unstyled m-0"
                        aria-labelledby="customProductManagement" data-parent="#accordion">
                        <li [ngClass]="currentUrl === '/custom-product/list-custom-product' ? 'active' : ''"
                            [routerLink]="['/custom-product/list-custom-product']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Custom Product</a></li>
                        <li [ngClass]="currentUrl === '/body-management/list-body' ? 'active' : ''"
                            [routerLink]="['/body-management/list-body']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Body Management</a></li>
                        <li [ngClass]="currentUrl === '/skirt-management/list-skirt' ? 'active' : ''"
                            [routerLink]="['/skirt-management/list-skirt']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Skirt Management</a></li>
                        <li [ngClass]="currentUrl === '/embellishment/list-embellishment' ? 'active' : ''"
                            [routerLink]="['/embellishment/list-embellishment']"><a> <i class="fa fa-bullseye"
                                    aria-hidden="true"></i>
                                Embellishment Management</a>
                        </li>
                    </ul>
                </div>
            </div> -->

            <!-- <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/custom-product/list-custom-product' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/custom-product/list-custom-product']"
                    id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-product-hunt" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Custom Product Management </span></a>
                </div>
            </div> -->

            <!-- <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/color/list-color' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/color/list-color']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-cog" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Color Management</span></a>
                </div>
            </div> -->

            <!-- <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/silhouette/list-silhouette' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/silhouette/list-silhouette']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-male" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Silhouette Management</span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/neckline/list-neckline' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/neckline/list-neckline']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-male" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Neckline Management</span></a>
                </div>
            </div> -->

            <!-- Trunk Show -->
            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/trunk/list-trunk' ? 'active' : ''">
                <div class="card-header clearfix">
                    <a class="side_menu" id="" data-toggle="collapse" data-target="#trunkManagement"
                        aria-expanded="false" aria-controls="trunkManagement" [routerLink]="['/trunk/list-trunk']">
                        <span class="side_menu_icon"><i class="fa fa-handshake-o" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Trunk Shows</span>
                    </a>
                    <i class="fa fa-chevron-down righticon" aria-hidden="true" data-toggle="collapse"
                        data-target="#trunkManagement" aria-expanded="false" aria-controls="trunkManagement"
                        [routerLink]="['/trunk/list-trunk']"></i>

                    <ul id="trunkManagement" class="collapse list-unstyled m-0" aria-labelledby="trunkManagement"
                        data-parent="#accordion">
                        <li [ngClass]="currentUrl === '/trunk/list-trunk' ? 'active' : ''"
                            [routerLink]="['/trunk/list-trunk']"><a> <i class="fa fa-bullseye" aria-hidden="true"></i>
                                Trunk Management</a>
                        </li>

                        <li [ngClass]="currentUrl === '/trade/list-trade' ? 'active' : ''"
                            [routerLink]="['/trade/list-trade']"><a> <i class="fa fa-bullseye" aria-hidden="true"></i>
                                Trade Management</a>
                        </li>

                    </ul>
                </div>
            </div>

            <!-- <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/trunk/list-trunk' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/trunk/list-trunk']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-handshake-o" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Trunk Management</span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/trade/list-trade' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/trade/list-trade']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-handshake-o" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Trade Management</span></a>
                </div>
            </div> -->


            <!-- <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/swatches/list-swatches' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/swatches/list-swatches']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-male" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Swatches Management</span></a>
                </div>
            </div>
            <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/embellishment/list-embellishment' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/embellishment/list-embellishment']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-male" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Embellishment Management</span></a>
                </div>
            </div> -->

            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/coupon/list-coupon' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/coupon/list-coupon']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-gift" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Coupon Management</span></a>
                </div>
            </div>
            <!-- <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/price/list-price' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/price/list-price']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-money" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Price Management </span></a>
                </div>
            </div> -->

            <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/tax-shipping-management/tax-shipping' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/tax-shipping-management/tax-shipping']"
                    id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-money" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Tax & Shipping Management </span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/transaction-management/list-transaction' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/transaction-management/list-transaction']"
                    id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-exchange" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Transaction Management</span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/appointment/appointment-list' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/appointment/appointment-list']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Appointment Management</span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/visitor/visitor-list' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/visitor/visitor-list']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-users" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Visitor Management</span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/order/list-order' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/order/list-order']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-first-order" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Order Management </span></a>
                </div>
            </div>

            <!-- <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/body-management/list-body' ? 'active': ''">
                <div class="card-header clearfix" [routerLink]="['/body-management/list-body']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-male" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Body Management</span></a>
                </div>
            </div> -->

            <!-- <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/skirt-management/list-skirt' ? 'active': ''">
                <div class="card-header clearfix" [routerLink]="['/skirt-management/list-skirt']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-male" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Skirt Management</span></a>
                </div>
            </div> -->

            <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/static-content-management/list-static-content' ? 'active': ''">
                <div class="card-header clearfix" [routerLink]="['/static-content-management/list-static-content']"
                    id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-file-text" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Static Content Management</span></a>
                </div>
            </div>
            <!-- <ul class="list-unstyled components menu-box toggle-menu m-0">
                <li class="active">
                    <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" class="card-header clearfix d-flex"><span class="side_menu_icon"><i class="fa fa-file-text" aria-hidden="true"></i></span><span
                        class="side_page_name head_color side-sub-menu-page" [ngClass]="currentUrl === '/static-content-management/list-static-content' ? 'active': ''">Static Content Management</span></a>
                    <ul class="collapse list-unstyled card-sub-header m-0 sub-menu-bdr" id="homeSubmenu">
                        <li class="card-header clearfix sub-menu-bdr" [ngClass]="currentUrl === '/static-content-management/list-static-content' ? 'active': ''"><a class="side_menu" [routerLink]="['/static-content-management/list-static-content']">   <span class="side_menu_icon"><i class="fa fa-file-text" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Static Content List</span></a></li>
                        <li class="card-header clearfix sub-menu-bdr" [ngClass]="currentUrl === '/blog/list-blog' ? 'active': ''"><a class="side_menu" [routerLink]="['/blog/list-blog']">   <span class="side_menu_icon"><i class="fa fa-file-text" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Blog</span></a></li>
                        <li class="card-header clearfix"><a class="side_menu">   <span class="side_menu_icon"><i class="fa fa-file-text" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Real Bride</span></a></li> 
                    </ul>
                </li>
            </ul> -->

            <div class="menu-box">
                <div class="card-header clearfix" id="headingOne">
                    <a class="side_menu" (click)="logOutModal()">
                        <span class="side_menu_icon">
                            <i class="fa fa-sign-out" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Logout</span>
                    </a>
                </div>
            </div>


            <span style="display: none;" id="blankId" class="collapse" aria-labelledby="blank" data-parent="#accordion">
            </span>
        </div>
    </div>

</aside>




<!-- logout modal -->
<div class="modal fade global-modal reset-modal" id="logoutModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Logout?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to logout?</p>
                                <div class="modal-btn-box">
                                    <button type="button" class="btn btn-theme" (click)="logout()">YES</button>
                                    <button type="button" class="btn btn-theme ml-2" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>