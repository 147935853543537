<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Arm Management</h1>
        </div>

        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">
                            <div class="sec_head_new sec_head_new_after">
                                <form [formGroup]="searchForm">
                                    <div class="row">
                                        <div class="col-md-4  pr-0">
                                            <div class="filter_search mb20 width100">
                                                <div class="input-group filter_search_group">
                                                    <input type="text" class="form-control overflow-text"
                                                        formControlName="search" placeholder="Search"
                                                        (keypress)="commonService.preventSpace($event)" maxlength="60">
                                                    <div class="input-group-append">
                                                        <button class="btn btn_search_group" type="button"><img
                                                                src="assets/img/icon-search.png" alt="Search"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="date-input-box">
                                                <input type="date" name="" id="fromDate"
                                                    class="form-control date-input mr-2" formControlName="fromDate"
                                                    [max]="today">
                                                <input type="date" name="" id="toDate" class="form-control date-input"
                                                    [readonly]="!this.searchForm.controls['fromDate'].value"
                                                    [min]="searchForm.controls['fromDate'].value"
                                                    formControlName="toDate">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="text-center admin-btn ">
                                                <button type="button" class="btn  btn-theme ml-2 Go_btn"
                                                    (click)="searchFormSubmit()">Submit</button>
                                                <button type="button" class="btn  btn-theme ml-2 Go_btn"
                                                    (click)="searchFormReset()">Reset</button>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="admin-btn justify-content-end ">
                                                <button type="button" class="btn  btn-theme ml-2 Go_btn"
                                                    (click)="addBody()">Add</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="hospital list">
                                <thead>
                                    <tr class="no_wrap_th text-center">
                                        <th scope="col" class="Sorting-img">S.No.</th>
                                        <th scope="col" class="Sorting-img">Arm Name </th>
                                        <th scope="col" class="Sorting-img">Arm Image</th>
                                        <th scope="col" class="Sorting-img">Created At</th>
                                        <th scope="col" class="action_td_btn3 Sorting-img">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of armListArray | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: totalItems}; let i = index"
                                        class="text-center">
                                        <td>{{itemPerPage * (currentPage-1)+ i+1}}</td>
                                        <td class="content">{{item?.armName || '--'}}</td>

                                        <td class="content">
                                            <span class="image-list-align">
                                                <img [src]="item?.rawImage?.webImage" alt="" style="height: 30px;">
                                            </span>
                                        </td>
                                        <td class="content">{{(item?.createdAt | date:'medium') || '--'}}</td>
                                        <td>
                                            <div class="justify-content-center d-flex">
                                                <button class="btn btn-info ml-2 bt-margin" title="view"
                                                    (click)="viewBody(item?._id)">
                                                    <i class="fa fa-eye" aria-hidden="true"
                                                        style="color:white"></i></button>
                                                <button class="btn btn-info ml-2 bt-margin" title="edit"
                                                    (click)="editBody(item?._id)"><i class="fa fa-edit"
                                                        aria-hidden="true" style="color:white"></i></button>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!armListArray?.length">
                                        <td class="table-no-record" colspan="4">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > 10">
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>