import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { ValidationMessage, RegEx } from 'src/app/config/validation-message';
declare var $: any;

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  validationMessage = ValidationMessage;

  forgotPasswordForm: FormGroup;

  resetId: any;
  otp: any;

  email: string;
  UserOtp: any;
  // currentUrl: string;
  previousUrl: string;
  userData: {};
  isActivate: any;
  countDowon: any = 2.59
  otpForm: FormGroup

  constructor(private router: Router, public commonService: CommonService) {
    // this.activate.queryParams.subscribe(res => {
    //   this.isActivate = res.isActive
    // })
    window.scroll(0, 0)
  }

  ngOnInit() {
    // forgot form validation
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(RegEx.email)])
    })

    // otp form validation
    this.otpForm = new FormGroup({ 
      otp: new FormControl("", [Validators.required]) 
    });

    this.contDownTimer()
    // this.email = localStorage.getItem('email')
    // this.currentUrl = localStorage.getItem('currentUrl')
    // console.log(this.currentUrl);
  }

  get f() { return this.forgotPasswordForm.controls }

  submit() {
    if (this.forgotPasswordForm.valid) {
      let apiReqData = {
        email: (this.forgotPasswordForm.value.email).toLowerCase()
      }
      this.commonService.showSpinner()
      this.commonService.postApi(ApiEndPoint.forgotPassword, apiReqData, 0).subscribe((res: any) => {
        if (res.responseCode == 200) {
          this.commonService.hideSpinner()
          this.commonService.successToast(res.responseMessage)
          $('#exampleModal').modal('show')
          // this.form.reset()
        } else {
          this.commonService.hideSpinner()
          this.commonService.errorToast(res.responseMessage)
        }
      }, (err) => {
        console.log(err);
        if (err.responseCode == 404) {
          this.commonService.hideSpinner()
          this.commonService.errorToast(err.responseMessage)
        }
      })
    } else {
      this.commonService.errorToast('Email is required.')
    }
  }

  //  otp verification 
  navigateToResetPassword() {
    console.log(this.UserOtp)
    let apiReqUrl = ApiEndPoint.verifyOtp
    this.userData = {
      email: this.forgotPasswordForm.value.email,
      otp: this.UserOtp
    }
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, this.userData, 0).subscribe(res => {
      console.log(res);
      if (res.responseCode == 200) {
        this.commonService.hideSpinner()
        this.commonService.successToast(res.responseMessage)
        $('#exampleModal').modal('hide')
        // localStorage.setItem('email',this.form.value.email)

        this.router.navigate(['/reset-password'], { queryParams: { email: this.forgotPasswordForm.value.email } })
        // if(!this.isActivate){
        //   this.router.navigate(['/homepage'])
        // }
        // if (this.currentUrl === '/auth/signUp') {
        //   this.router.navigate(['/auth/login'])
        // } else {
        //   localStorage.setItem('verifyOtpToken', res['result']['token'])
        //   this.router.navigate(['/auth/reset-password'])
        // }
      } else {
        this.commonService.hideSpinner()
        $('#exampleModal').modal('hide')
      }

    }, err => {
      this.commonService.hideSpinner()
      if (err['error']['responseCode'] == 400) {
        this.commonService.warningToast(err['error']['responseMessage'])
      }
      console.log(err);
    })

  }

  contDownTimer() {
    var a = window.setInterval(() => {
      if (this.countDowon - (parseInt(this.countDowon)) > 0.60) {

        this.countDowon = this.countDowon - 0.40
      }
      if (this.countDowon > 0) {
        this.countDowon = this.countDowon - 0.01
        this.getTime((this.countDowon).toFixed(2))
      }
      else {
        this.resend_otp_active = true
        clearInterval(a)
      }
    }, 1000)
  }

  resend_otp_active: boolean = false
  time

  getTime(e) {
    this.time = String(e).replace('.', ':')
    this.time = String(e).replace('-', '')
  }

  onOtpChange(otp) {
    this.UserOtp = otp;
    console.log(otp);

  }


  // resend otp 
  resendOtp() {
    if (!this.resend_otp_active) {
      return
    }
    else {
      this.countDowon = 2.59
      this.contDownTimer()
      this.resend_otp_active = false
    }
    // this.email = localStorage.getItem('email');
    var email = {
      'email': this.forgotPasswordForm.value.email
    }
    this.commonService.showSpinner()
    this.commonService.postApi(ApiEndPoint.resendOtp, email, 0).subscribe(res => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner()
        this.commonService.successToast(res.responseMessage)
      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)

      }
      console.log(res);
    }, err => {
      console.log(err);
    })
  }
}

