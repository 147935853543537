<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Collection Category / View</h1>
        </div>
        <div class="content-section">
            <div class="order-view  setting-page">
                <div class="mb40">
                    <div class="input-style mb20 mt20 view-label-align">
                        <!-- top section collection name -->
                        <div class="row">
                            <div class="col-6">
                                <div class="d-flex justify-content-between">
                                    <div class="form-group">
                                        <label>Collection Name <span>:</span></label>
                                    </div>
                                    <div class="form-group" style="width:300px">
                                        <p>{{collectionDetails?.collectionName}}</p>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <div class="form-group">
                                        <label>Collection Image <span>:</span></label>
                                    </div>
                                    <div class="form-group" style="width:300px">
                                        <img class="w-50"
                                            [src]="collectionDetails?.image?.webImage || '../../../../assets/img/dummy-image-green.png'"
                                            alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row" style="margin: 25px 0;">
                            <h5 style="font-size: 18px;color: #aba6a6;">Body, skirt & embellishment
                                with add-on price.</h5>
                        </div>
                        <!-- body,skirt,embelishment section -->
                        <div class="mt-20 row custome-row-responsive">
                            <!-- body section start -->
                            <div *ngIf="collectionDetails?.bodyArray?.length" class="card m-1 p-2" style="width: 22%;">
                                <label>Body</label>
                                <div *ngFor="let item of collectionDetails?.bodyArray; let i= index"
                                    class="d-flex justify-content-around">
                                    <div class="form-group">
                                        <label>Body Name<span>:</span></label>
                                    </div>
                                    <div class="form-group">
                                        <p>{{item?.bodyId?.bodyName}}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- body section end -->

                            <!-- skirt section start -->
                            <div *ngIf="collectionDetails?.skirtArray?.length" class="card m-1 p-2" style="width: 22%;">
                                <label>Skirt</label>
                                <div *ngFor="let item of collectionDetails?.skirtArray; let i= index"
                                    class="d-flex justify-content-around">
                                    <div class="form-group">
                                        <label>Skirt Name<span>:</span></label>
                                    </div>
                                    <div class="form-group">
                                        <p>{{item?.skirtId?.skirtName}}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- skirt section end -->

                            <!-- embellishment section start -->
                            <div *ngIf="collectionDetails?.embellishmentArray?.length" class="card m-1 p-2"
                                style="width: 22%;">
                                <label>Embellishment</label>
                                <div *ngFor="let item of collectionDetails?.embellishmentArray; let i= index"
                                    class="d-flex justify-content-around">
                                    <div class="form-group">
                                        <label>Embelishment Name<span>:</span></label>
                                    </div>
                                    <div class="form-group">
                                        <p>{{item?.embellishmentId?.embellishment_Type}}</p>
                                    </div>
                                </div>

                            </div>

                        
                        </div>

                    </div>
                    <hr>
                    <!-- choose size section -->
                    <!-- <div class="row mt40 justify-content-around">
                        <div class="col-md-5 card">
                            <label>Size<span>:</span></label>
                            <div *ngFor="let item of collectionDetails?.size" class="d-flex justify-content-between">
                                <div class="form-group">
                                    <label>{{item?.type}}<span>:</span></label>
                                </div>
                                <div class="form-group" style="width:300px">
                                    <p>{{item?.addOnPrice}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-5 card">
                            <label>Extra Length Add-on Price<span>:</span></label>
                            <div *ngFor="let item of collectionDetails?.extraLength">
                                <div class="d-flex justify-content-between">
                                    <div class="form-group">
                                        <label>Length<span>:</span></label>
                                    </div>
                                    <div class="form-group" style="width:300px">
                                        <p>{{item?.type}}</p>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <div class="form-group">
                                        <label>Add-on Price<span>:</span></label>
                                    </div>
                                    <div class="form-group" style="width:300px">
                                        <p>{{item?.addOnPrice}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div>

                    </div> -->

                    <!-- price section -->
                    <!--  -->
                    <div class="mt40">
                        <div class="d-flex justify-content-between">
                            <h3>Cost Price</h3>
                            <div class="d-flex"><span class="base-price-cls">Base Price:</span>
                                <input class="form-control" type="text" style="margin-bottom: 5px;"
                                    [(ngModel)]="basePriceCostPrice" [ngModelOptions]="{standalone: true}"
                                    (keypress)="commonService.restrictChar($event)" [maxlength]="maxLengthOfPriceFields"
                                    [disabled]="true">
                            </div>
                        </div>
                        <div style="overflow:auto;">
                            <table class="table table-bordered">
                                <thead class="text-center">
                                    <tr>
                                        <th scope="col" rowspan="2">Price Type</th>
                                        <th scope="col" rowspan="2">Body</th>
                                        <th scope="col" rowspan="2">Diamond</th>
                                        <!-- <th scope="col" rowspan="2">MRP</th> -->
                                        <th *ngIf="embellishmentPriceHeader?.length"
                                            [attr.colspan]="embellishmentPriceHeader?.length">
                                            Embellishment</th>
                                        <th *ngIf="skirtPriceHeader?.length"
                                            [attr.colspan]="skirtPriceHeader?.length * 2">Skirt</th>
                                        <th colspan="3">Size</th>
                                        <th rowspan="2">Standard Colors</th>
                                        <th rowspan="2">Premium Colors</th>
                                        <!-- <th scope="col" rowspan="2">Total</th> -->
                                    </tr>
                                    <!-- embellishment -->
                                    <tr>
                                        <ng-container *ngIf="embellishmentPriceHeader?.length">
                                            <ng-container *ngFor="let embItem of embellishmentPriceHeader">
                                                <th scope="col">
                                                    {{embItem?.embellishment_Type}}</th>
                                            </ng-container>
                                        </ng-container>

                                        <!-- <th scope="col">Petal</th>
                                                        <th scope="col">Polka</th>
                                                        <th scope="col">Encrusted</th> -->
                                        <!-- </tr> -->

                                        <!-- skirt -->
                                        <!-- <tr> -->
                                        <ng-container *ngIf="skirtPriceHeader?.length">
                                            <ng-container *ngFor="let skirtItem of skirtPriceHeader">
                                                <th scope="col">{{skirtItem?.skirtName}}</th>
                                                <th scope="col">Longer Length In {{skirtItem?.skirtName}}</th>
                                            </ng-container>
                                        </ng-container>
                                        <!-- <th scope="col">GGT Skirt</th>
                                                                            <th scope="col">Longer Length in GGT</th>
                                                                            <th scope="col">Tulle Skirt</th>
                                                                            <th scope="col">Longer Length in Tulle</th>
                                                                            <th scope="col">Full Skirt</th>
                                                                            <th scope="col">Longer Length in Full Skirt</th> -->
                                        <!-- </tr> -->

                                        <!-- size -->
                                        <!-- <tr> -->
                                        <th scope="col">0-18</th>
                                        <th scope="col">20-24</th>
                                        <th scope="col">26-32</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <!-- <ng-template ngForOF let-item [ngFor]="collectionArray">
                                                                            <tr>
                                                                                <td>1200</td>
                                                                                <td>XYZ</td>
                                                                                <td>
                                                                                    <select>
                                                                                        <option value="">1</option>
                                                                                    </select>
                                                                                </td>
                                                                                <ng-template ngForOF let-emblishment [ngFor]="item.diamond">
                            
                                                                                    <td>{{emblishment.embellishmentName}}</td>
                                                                                </ng-template>
                                                                                <ng-template ngForOF let-skirt [ngFor]="item.skirt">
                                                                                    <td>{{skirt.skirtName}}</td>
                                                                                </ng-template>
                                                                                <ng-template ngForOF let-size [ngFor]="item.size">
                                                                                    <td>{{skirt.sizeName}}</td>
                                                                                </ng-template>
                                                                                <td>Stan</td>
                                                                                <td>premium</td>
                                                                                <td>total</td>
                                                                            </tr>
                                                                        </ng-template> -->
                                    <tr *ngFor="let item of bodyListCostPrice">
                                        <td>Cost Price</td>
                                        <td>
                                            <div class="form-group  d-flex justify-content-between">
                                                <select class="col-md-10 form-control custom-input"
                                                    [(ngModel)]="item.bodyId" [ngModelOptions]="{standalone: true}"
                                                    [disabled]="true">
                                                    <option value="">Select Body</option>
                                                    <option *ngFor="let data of bodyListArray" [value]="data._id">
                                                        {{data?.bodyName}}</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group  d-flex justify-content-between">
                                                <select class="col-md-10 form-control custom-input"
                                                    [(ngModel)]="item.diamondId" [ngModelOptions]="{standalone: true}"
                                                    [disabled]="true">
                                                    <option value="">Select Diamond</option>
                                                    <option *ngFor="let data of diamondListArray" [value]="data._id">
                                                        {{data?.diamondName}}</option>
                                                </select>
                                            </div>
                                        </td>
                                        <ng-container *ngIf="item?.embellishment?.length">

                                            <ng-container *ngFor="let embItem of item?.embellishment">
                                                <td>
                                                    <input type="text" class="form-control" [(ngModel)]="embItem.price"
                                                        [ngModelOptions]="{standalone:true}"
                                                        (keypress)="commonService.restrictChar($event)"
                                                        [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                                </td>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="item?.skirt?.length">
                                            <ng-container *ngFor="let skirtItem of item?.skirt">
                                                <td>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="skirtItem.price"
                                                        [ngModelOptions]="{standalone:true}"
                                                        (keypress)="commonService.restrictChar($event)"
                                                        [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="skirtItem.extraLength"
                                                        [ngModelOptions]="{standalone:true}"
                                                        (keypress)="commonService.restrictChar($event)"
                                                        [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                                </td>
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngFor="let sizeItem of item?.size">
                                            <td>
                                                <input type="text" class="form-control" [(ngModel)]="sizeItem.price"
                                                    [ngModelOptions]="{standalone:true}"
                                                    (keypress)="commonService.restrictChar($event)"
                                                    [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                            </td>
                                        </ng-container>
                                        <td>
                                            <input type="text" class="form-control" [(ngModel)]="item.standardColor"
                                                [ngModelOptions]="{standalone:true}"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" [(ngModel)]="item.premiumColor"
                                                [ngModelOptions]="{standalone:true}"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!--  -->

                    <!--  -->
                    <div class="mt40">
                        <div class="d-flex justify-content-between">
                            <h3>Whole Sale Price</h3>
                            <div class="d-flex"><span class="base-price-cls">Base Price:</span>
                                <input class="form-control" type="text" style="margin-bottom: 5px;"
                                    [(ngModel)]="basePriceWholeSale" [ngModelOptions]="{standalone: true}"
                                    (keypress)="commonService.restrictChar($event)" [maxlength]="maxLengthOfPriceFields"
                                    [disabled]="true">
                            </div>
                        </div>
                        <div style="overflow:auto;">
                            <table class="table table-bordered">
                                <thead class="text-center">
                                    <tr>
                                        <th scope="col" rowspan="2">Price Type</th>
                                        <th scope="col" rowspan="2">Body</th>
                                        <th scope="col" rowspan="2">Diamond</th>
                                        <!-- <th scope="col" rowspan="2">MRP</th> -->
                                        <th *ngIf="embellishmentPriceHeader?.length"
                                            [attr.colspan]="embellishmentPriceHeader?.length">
                                            Embellishment</th>
                                        <th *ngIf="skirtPriceHeader?.length"
                                            [attr.colspan]="skirtPriceHeader?.length * 2">Skirt</th>
                                        <th colspan="3">Size</th>
                                        <th rowspan="2">Standard Colors</th>
                                        <th rowspan="2">Premium Colors</th>
                                        <!-- <th scope="col" rowspan="2">Total</th> -->
                                    </tr>
                                    <!-- embellishment -->
                                    <tr>
                                        <ng-container *ngIf="embellishmentPriceHeader?.length">
                                            <ng-container *ngFor="let embItem of embellishmentPriceHeader">
                                                <th scope="col">
                                                    {{embItem?.embellishment_Type}}</th>
                                            </ng-container>
                                        </ng-container>

                                        <!-- <th scope="col">Petal</th>
                            <th scope="col">Polka</th>
                            <th scope="col">Encrusted</th> -->
                                        <!-- </tr> -->

                                        <!-- skirt -->
                                        <!-- <tr> -->
                                        <ng-container *ngIf="skirtPriceHeader?.length">
                                            <ng-container *ngFor="let skirtItem of skirtPriceHeader">
                                                <th scope="col">{{skirtItem?.skirtName}}</th>
                                                <th scope="col">Longer Length In {{skirtItem?.skirtName}}</th>
                                            </ng-container>
                                        </ng-container>
                                        <!-- <th scope="col">GGT Skirt</th>
                                                <th scope="col">Longer Length in GGT</th>
                                                <th scope="col">Tulle Skirt</th>
                                                <th scope="col">Longer Length in Tulle</th>
                                                <th scope="col">Full Skirt</th>
                                                <th scope="col">Longer Length in Full Skirt</th> -->
                                        <!-- </tr> -->

                                        <!-- size -->
                                        <!-- <tr> -->
                                        <th scope="col">0-18</th>
                                        <th scope="col">20-24</th>
                                        <th scope="col">26-32</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr *ngFor="let item of bodyListWholeSale">
                                        <td>Whole Sale Price</td>
                                        <td>
                                            <div class="form-group  d-flex justify-content-between">
                                                <select class="col-md-10 form-control custom-input"
                                                    [(ngModel)]="item.bodyId" [ngModelOptions]="{standalone: true}"
                                                    [disabled]="true">
                                                    <option value="">Select Body</option>
                                                    <option *ngFor="let data of bodyListArray" [value]="data._id">
                                                        {{data?.bodyName}}</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group  d-flex justify-content-between">
                                                <select class="col-md-10 form-control custom-input"
                                                    [(ngModel)]="item.diamondId" [ngModelOptions]="{standalone: true}"
                                                    [disabled]="true">
                                                    <option value="">Select Diamond</option>
                                                    <option *ngFor="let data of diamondListArray" [value]="data._id">
                                                        {{data?.diamondName}}</option>
                                                </select>
                                            </div>
                                        </td>
                                        <ng-container *ngIf="item?.embellishment?.length">

                                            <ng-container *ngFor="let embItem of item?.embellishment">
                                                <td>
                                                    <input type="text" class="form-control" [(ngModel)]="embItem.price"
                                                        [ngModelOptions]="{standalone:true}"
                                                        (keypress)="commonService.restrictChar($event)"
                                                        [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                                </td>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="item?.skirt?.length">
                                            <ng-container *ngFor="let skirtItem of item?.skirt">
                                                <td>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="skirtItem.price"
                                                        [ngModelOptions]="{standalone:true}"
                                                        (keypress)="commonService.restrictChar($event)"
                                                        [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="skirtItem.extraLength"
                                                        [ngModelOptions]="{standalone:true}"
                                                        (keypress)="commonService.restrictChar($event)"
                                                        [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                                </td>
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngFor="let sizeItem of item?.size">
                                            <td>
                                                <input type="text" class="form-control" [(ngModel)]="sizeItem.price"
                                                    [ngModelOptions]="{standalone:true}"
                                                    (keypress)="commonService.restrictChar($event)"
                                                    [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                            </td>
                                        </ng-container>
                                        <td>
                                            <input type="text" class="form-control" [(ngModel)]="item.standardColor"
                                                [ngModelOptions]="{standalone:true}"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" [(ngModel)]="item.premiumColor"
                                                [ngModelOptions]="{standalone:true}"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!--  -->

                    <!--  -->
                    <div class="mt40">
                        <div class="d-flex justify-content-between">
                            <h3>MRP</h3>
                            <div class="d-flex"><span class="base-price-cls">Base Price:</span>
                                <input class="form-control" type="text" style="margin-bottom: 5px;"
                                    [(ngModel)]="basePriceMRP" [ngModelOptions]="{standalone: true}"
                                    (keypress)="commonService.restrictChar($event)" [maxlength]="maxLengthOfPriceFields"
                                    [disabled]="true">
                            </div>
                        </div>
                        <div style="overflow:auto;">
                            <table class="table table-bordered">
                                <thead class="text-center">
                                    <tr>
                                        <th scope="col" rowspan="2">Price Type</th>
                                        <th scope="col" rowspan="2">Body</th>
                                        <th scope="col" rowspan="2">Diamond</th>
                                        <!-- <th scope="col" rowspan="2">MRP</th> -->
                                        <th *ngIf="embellishmentPriceHeader?.length"
                                            [attr.colspan]="embellishmentPriceHeader?.length">
                                            Embellishment</th>
                                        <th *ngIf="skirtPriceHeader?.length"
                                            [attr.colspan]="skirtPriceHeader?.length * 2">Skirt</th>
                                        <th colspan="3">Size</th>
                                        <th rowspan="2">Standard Colors</th>
                                        <th rowspan="2">Premium Colors</th>
                                        <!-- <th scope="col" rowspan="2">Total</th> -->
                                    </tr>
                                    <!-- embellishment -->
                                    <tr>
                                        <ng-container *ngIf="embellishmentPriceHeader?.length">
                                            <ng-container *ngFor="let embItem of embellishmentPriceHeader">
                                                <th scope="col">
                                                    {{embItem?.embellishment_Type}}</th>
                                            </ng-container>
                                        </ng-container>

                                        <!-- <th scope="col">Petal</th>
                                                        <th scope="col">Polka</th>
                                                        <th scope="col">Encrusted</th> -->
                                        <!-- </tr> -->

                                        <!-- skirt -->
                                        <!-- <tr> -->
                                        <ng-container *ngIf="skirtPriceHeader?.length">
                                            <ng-container *ngFor="let skirtItem of skirtPriceHeader">
                                                <th scope="col">{{skirtItem?.skirtName}}</th>
                                                <th scope="col">Longer Length In {{skirtItem?.skirtName}}</th>
                                            </ng-container>
                                        </ng-container>
                                        <!-- <th scope="col">GGT Skirt</th>
                                                                            <th scope="col">Longer Length in GGT</th>
                                                                            <th scope="col">Tulle Skirt</th>
                                                                            <th scope="col">Longer Length in Tulle</th>
                                                                            <th scope="col">Full Skirt</th>
                                                                            <th scope="col">Longer Length in Full Skirt</th> -->
                                        <!-- </tr> -->

                                        <!-- size -->
                                        <!-- <tr> -->
                                        <th scope="col">0-18</th>
                                        <th scope="col">20-24</th>
                                        <th scope="col">26-32</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of bodyListMRP">
                                        <td>MRP</td>
                                        <td>
                                            <div class="form-group  d-flex justify-content-between">
                                                <select class="col-md-10 form-control custom-input"
                                                    [(ngModel)]="item.bodyId" [ngModelOptions]="{standalone: true}"
                                                    [disabled]="true">
                                                    <option value="">Select Body</option>
                                                    <option *ngFor="let data of bodyListArray" [value]="data._id">
                                                        {{data?.bodyName}}</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group  d-flex justify-content-between">
                                                <select class="col-md-10 form-control custom-input"
                                                    [(ngModel)]="item.diamondId" [ngModelOptions]="{standalone: true}"
                                                    [disabled]="true">
                                                    <option value="">Select Diamond</option>
                                                    <option *ngFor="let data of diamondListArray" [value]="data._id">
                                                        {{data?.diamondName}}</option>
                                                </select>
                                            </div>
                                        </td>
                                        <ng-container *ngIf="item?.embellishment?.length">

                                            <ng-container *ngFor="let embItem of item?.embellishment">
                                                <td>
                                                    <input type="text" class="form-control" [(ngModel)]="embItem.price"
                                                        [ngModelOptions]="{standalone:true}"
                                                        (keypress)="commonService.restrictChar($event)"
                                                        [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                                </td>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="item?.skirt?.length">
                                            <ng-container *ngFor="let skirtItem of item?.skirt">
                                                <td>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="skirtItem.price"
                                                        [ngModelOptions]="{standalone:true}"
                                                        (keypress)="commonService.restrictChar($event)"
                                                        [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="skirtItem.extraLength"
                                                        [ngModelOptions]="{standalone:true}"
                                                        (keypress)="commonService.restrictChar($event)"
                                                        [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                                </td>
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngFor="let sizeItem of item?.size">
                                            <td>
                                                <input type="text" class="form-control" [(ngModel)]="sizeItem.price"
                                                    [ngModelOptions]="{standalone:true}"
                                                    (keypress)="commonService.restrictChar($event)"
                                                    [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                            </td>
                                        </ng-container>
                                        <td>
                                            <input type="text" class="form-control" [(ngModel)]="item.standardColor"
                                                [ngModelOptions]="{standalone:true}"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" [(ngModel)]="item.premiumColor"
                                                [ngModelOptions]="{standalone:true}"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" [disabled]="true" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!--  -->
                    <!-- end price section -->


                </div>
                <div class="text-center mt40 mb40">
                    <button class="btn btn-large  max-WT-150 btn-theme"
                        routerLink="/collection-management/list-collection">Back</button>
                </div>
            </div>
        </div>
    </main>
</div>