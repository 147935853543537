import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
import { ExcelService } from 'src/app/provider/excel.service';
declare var $: any;
@Component({
  selector: 'app-vat-countries-list',
  templateUrl: './vat-countries-list.component.html',
  styleUrls: ['./vat-countries-list.component.scss']
})
export class VatCountriesListComponent implements OnInit {

  searchForm: FormGroup;
  // categoryListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;

  today = new Date().toISOString().split('T')[0]
  // vatDetails: any = (colorList  as  any).default
  vatDetails: any
  silhouetteId: any;
  tradeId: any;

  constructor(private router: Router, public commonService: CommonService, public excelService: ExcelService) { }

  ngOnInit() {
    this.searchFormValidation();
    this.getTrunkList();
    console.log(this.today)
  }

  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      // status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.getTrunkList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        // status: '',
        fromDate: '',
        toDate: ''
      });
      this.getTrunkList()
    }
  }

  // -------------------- get category list --------------------- //
  getTrunkList() {
    let apiReqUrl: any = `${ApiEndPoint.listVat}`
    let data = {
      page: this.currentPage,
      limit: this.itemPerPage
    }
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, data, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.vatDetails = res.result.docs ? res.result.docs : '';
        this.totalItems = res.result.total
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.vatDetails = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      console.log(err);
      this.commonService.errorToast(err.responseMessage)
      this.commonService.hideSpinner();
      if (err.responseCode == 404) {
        this.vatDetails = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }

    })
  }

  editTrunk(trunkId) {
    this.router.navigate(['/tax-shipping-management/edit-Vat'], { queryParams: { trunkId: trunkId } })
  }

  pagination(event) {
    this.currentPage = event;
    this.getTrunkList()
  }

  addTrunk() {
    this.router.navigate(['/trunk/add-trunk'])
  }


}
