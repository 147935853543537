<div class="wrapper" style="margin-bottom: 5px;">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Edit VAT</h1>
        </div>
        <div class="">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <!-- <div class="row justify-content-end">
                    <button class="btn" [routerLink]="['/tax-shipping-management/vat-list']" style="color: #000;">Vat
                        Details</button>
                </div> -->
                <div class="mb40">
                    <div class="add-store-block input-style mb20 mt20 view-label-align">
                        <form #f="ngForm">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-12 textalignright ">Country For VAT / Sales tax purposes:</label>
                                <div class="col-md-12 textaligncenter mt-1">
                                    <div class="form-group">
                                        <select class="form-control" name="nameOfCount" [(ngModel)]="nameOfCountry"
                                            #nameOfCount="ngModel" style="outline: none; box-shadow: none;"
                                            (change)="countryName($event.target.value)" required>
                                            <option value="" selected disabled>Select Country</option>
                                            <option *ngFor="let item of listCountry"
                                                [selected]="allData?.country == item?.countryName"
                                                [value]="item?.countryName">
                                                {{item?.countryName}}</option>
                                        </select>
                                        <div *ngIf="nameOfCount.invalid && nameOfCount.touched " style="color: red;">
                                            Country Name
                                            is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-12 textalignright ">Do you need to charge VAT?:</label>
                                <div class="col-md-12 textaligncenter mt-1">
                                    <div class="form-group">
                                        <select class="form-control" #vStatus="ngModel" [(ngModel)]="vatFeild" required
                                            name="vStatus" ngModel (change)="vatStatus($event.target.value)"
                                            style="outline: none; box-shadow: none;">
                                            <option value="" disabled>Select</option>
                                            <option value="YES" [selected]="allData?.doYouNeedToChargeVat == 'YES'">Yes
                                            </option>
                                            <option value="NO" [selected]="allData?.doYouNeedToChargeVat == 'NO'">No
                                            </option>
                                        </select>
                                        <div *ngIf="vStatus.invalid && vStatus.touched" style="color: red;">Status is
                                            required</div>

                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline" *ngIf="vatFeild == 'YES' ">
                                <label class="col-md-12 textalignright " style="justify-content: start;">VAT Number
                                    <span>(if applicable):</span></label>
                                <div class="col-md-12 textaligncenter mt-1">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter VAT Number"
                                            #vNumber="ngModel" ngModel name="vNumber" required [(ngModel)]="vatNumber"
                                            (keypress)="commonService.restrictChar($event)">
                                        <div *ngIf="vNumber.invalid && vNumber.touched" style="color: red;">VAT number
                                            is
                                            required</div>

                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">VAT Rates:</label>
                            </div>
                            <div class="form-group row align-items-baseline">

                                <table class="table table-bordered">
                                    <thead class="text-center">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Rate(%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of vatTax;let i = index">
                                            <tr>
                                                <th><input type="text" class="form-control" [(ngModel)]="item.name"
                                                        [ngModelOptions]="{standalone: true}">
                                                </th>
                                                <td><input type="text" class="form-control" placeholder="%"
                                                        [(ngModel)]="item.rates" [ngModelOptions]="{standalone: true}"
                                                        (keypress)="commonService.restrictChar($event)" /></td>
                                                <td>
                                                    <p (click)="removeVatFeildi(i)" class="crossBtn"><i
                                                            class="fa fa-times" aria-hidden="true"></i></p>
                                                </td>
                                            </tr>
                                        </ng-container>


                                    </tbody>
                                </table>
                                <p><button class="vatBtn" (click)="addTax()">+ VAT Rate</button></p>
                            </div>
                            <div class="text-center mt40 mb40">

                                <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="f.invalid"
                                    (click)="addVat()">Save</button>
                                <button class="btn btn-large  max-WT-150 btn-theme"
                                    [routerLink]="['/tax-shipping-management/vat-list']">Back</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>