<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Collection Category / Edit</h1>
        </div>
        <div class="content-section">
            <div class="order-view  setting-page">
                <div class="mb40">
                    <div class="input-style mb20 mt20 view-label-align">
                        <form [formGroup]="addCategoryForm" #f="ngForm">
                            <!-- top section collection name -->
                            <div class="row">
                                <div class="col-6">
                                    <div class="d-flex justify-content-between">
                                        <div class="form-group">
                                            <label>Collection Name <span>:</span></label>
                                        </div>
                                        <div class="form-group" style="width:300px">
                                            <input type="text" class="form-control" placeholder="Enter Collection Name"
                                                formControlName='collectionName' maxlength="60">
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-between">
                                        <div class="form-group">
                                            <label>Collection Image <span>:</span></label>
                                        </div>
                                        <div class="form-group" style="width:300px">
                                            <label for="file-upload" class="custom-file-upload">
                                                Upload Image
                                            </label>
                                            <input id="file-upload" type="file" class="form-control"
                                                (change)="uploadImage($event.target.files[0])"
                                                formControlName='collectionImage'>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 d-flex justify-content-center">
                                    <img class="w-50" [src]="imageUrl?.webImage || '../../../../assets/img/dummy.png'"
                                        alt="">
                                </div>
                            </div>
                            <hr>
                            <div class="row" style="margin: 25px 0;">
                                <h5 style="font-size: 18px;color: #aba6a6;">Please select the body, skirt &
                                    embellishment with add-on price.</h5>
                            </div>
                            <!-- body,skirt,embelishment section -->
                            <div class="mt-20 row ">
                                <!-- body section start -->
                                <div class="col-md-3 ">
                                    <div class="card p-3">
                                        <label>Body</label>
                                        <div *ngFor="let item of bodyArrayList; let i= index">
                                            <!-- <div class="col-md-6 p-0">
                                            <select class="col-md-12 form-control" [(ngModel)]="item.bodyId"
                                                [ngModelOptions]="{standalone: true}">
                                                <option value="">Select Body</option>
                                                <option *ngFor="let data of bodyListArray" [value]="data._id">
                                                    {{data?.bodyName}}</option>
                                            </select>
                                        </div> -->

                                            <div class="">
                                                <div class="form-group  d-flex justify-content-between">
                                                    <select class="col-md-10 form-control custom-input"
                                                        [(ngModel)]="item.bodyId" [ngModelOptions]="{standalone: true}"
                                                        (change)="checkForDuplicate(item,i, 'BODY')"
                                                        [disabled]="item.bodyId != ''">
                                                        <option value="">Select Body</option>
                                                        <option *ngFor="let data of bodyListArray" [value]="data._id">
                                                            {{data?.bodyName}}</option>
                                                    </select>
                                                    <i class="fa fa-times" aria-hidden="true"
                                                        (click)="removeBodySectionDynamic(i)"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <button class="btn  btn-theme Go_btn mt-2 mb-2"
                                                (click)="addBodySectionDynamic()"> Add Body</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- body section end -->

                                <!-- skirt section start -->
                                <div class="col-md-3 ">
                                    <div class="card p-3">
                                        <label>Skirt</label>
                                        <div *ngFor="let item of skirtArrayList;let i =index">
                                            <!-- <div class="col-md-6 p-0">
                                            <select name="" id="" class="col-md-12 form-control"
                                                [(ngModel)]="item.skirtId" [ngModelOptions]="{standalone: true}">
                                                <option value="">Select Skirt</option>
                                                <option *ngFor="let data of skirtListArray" [value]="data?._id">
                                                    {{data?.skirtName}}</option>
                                            </select>
                                        </div> -->

                                            <div class="">
                                                <div class="form-group  d-flex justify-content-between">
                                                    <select name="" id="" class="col-md-10 form-control"
                                                        [(ngModel)]="item.skirtId" [ngModelOptions]="{standalone: true}"
                                                        (change)="checkForDuplicate(item,i, 'SKIRT')"
                                                        [disabled]="item.skirtId != ''">
                                                        <option value="">Select Skirt</option>
                                                        <option *ngFor="let data of skirtListArray" [value]="data?._id">
                                                            {{data?.skirtName}}</option>
                                                    </select>
                                                    <i class="fa fa-times" aria-hidden="true"
                                                        (click)="removeSkirtSectionDynamic(i)"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <button class="btn  btn-theme Go_btn mt-2 mb-2"
                                                (click)="addSkirtSectionDynamic()"> Add Skirt</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- skirt section end -->

                         

                                <div class="col-md-3 ">
                                    <div class="card p-3">
                                        <label>Embellishment</label>
                                        <div *ngFor="let item of embellishmentArrayList; let i = index">
                                            <!-- <div class="col-md-6 p-0">
                                            <select name="" id="" class="col-md-12 form-control"
                                                [(ngModel)]="item.embellishmentId"
                                                [ngModelOptions]="{standalone: true}">
                                                <option value="">Select Embellishment</option>
                                                <option *ngFor="let data of embellishmentListArray" [value]="data?._id">
                                                    {{data?.embellishment_Type}}</option>
                                            </select>
                                        </div> -->

                                            <div class="">
                                                <!-- <label for="">Add-on Price</label> -->
                                                <div class="form-group  d-flex justify-content-between">
                                                    <select name="" id="" class="col-md-10 form-control"
                                                        [(ngModel)]="item.embellishmentId"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (change)="checkForDuplicate(item,i, 'EMBELLISHMENT')"
                                                        [disabled]="item.embellishmentId != ''">
                                                        <option value="">Select Embellishment</option>
                                                        <option *ngFor="let data of embellishmentListArray"
                                                            [value]="data?._id">
                                                            {{data?.embellishment_Type}}</option>
                                                    </select>
                                                    <i class="fa fa-times" aria-hidden="true"
                                                        (click)="removeEmbellishmentSectionDynamic()"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <button class="btn  btn-theme Go_btn mt-2 mb-2"
                                                (click)="addEmbellishmentSectionDynamic()"> Add
                                                Embellishment</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr>
                            <!-- choose size section -->
                            <!-- <div class="row mt40">
                                <div class="col-md-6">
                                    <div class="card p-3">
                                        <div class="col-md-12 d-flex">
                                            <label for="">Size<span>:</span></label>
                                        </div>
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <div class="form-group col-md-4">
                                                <label>Regular<span>:</span></label>
                                            </div>
                                            <div class="form-group col-md-8 d-flex">
                                                <input type="text" class="form-control" placeholder="Enter Add-on Price"
                                                    formControlName="regularSizePrice">
                                                <i class="fa fa-info-circle m-2" aria-hidden="true"
                                                    data-toggle="tooltip" title="Size range 0 to 16"></i>

                                            </div>
                                        </div>

                                        <div class="col-md-12 d-flex justify-content-between">
                                            <div class="form-group col-md-4">
                                                <label>Large<span>:</span></label>
                                            </div>
                                            <div class="form-group col-md-8 d-flex">
                                                <input type="text" class="form-control" placeholder="Enter Add-on Price"
                                                    formControlName="largeSizePrice">
                                                <i class="fa fa-info-circle m-2" aria-hidden="true"
                                                    data-toggle="tooltip" title="Size range 18 to 22"></i>
                                            </div>
                                        </div>

                                        <div class="col-md-12 d-flex justify-content-between">
                                            <div class="form-group col-md-4">
                                                <label>X-Large<span>:</span></label>
                                            </div>
                                            <div class="form-group col-md-8 d-flex">
                                                <input type="text" class="form-control" placeholder="Enter Add-on Price"
                                                    formControlName="xLargeSizePrice">
                                                <i class="fa fa-info-circle m-2" aria-hidden="true"
                                                    data-toggle="tooltip" title="Size range 24 to above"></i>

                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <div class="col-md-6">
                                    <div class="card p-3">
                                        <label>Length</label>
                                        <div *ngFor="let item of lengthArray; let i= index" class="d-flex">
                                            <div class="col-md-6 p-0">
                                                <input type="text" class="col-md-12 form-control"
                                                    placeholder="Enter Length" maxlength="60" [(ngModel)]="item.type"
                                                    [ngModelOptions]="{standalone:'true'}">
                                            </div>

                                            <div class="col-md-6 p-0 ml-2">
                                                <div class="form-group  d-flex justify-content-between">
                                                    <input type="text" class="col-md-10 form-control custom-input"
                                                        placeholder="Add-on Price" [(ngModel)]="item.addOnPrice"
                                                        [ngModelOptions]="{standalone: true}">
                                                    <i class="fa fa-times" aria-hidden="true"
                                                        (click)="removeExtraLengthDynamic(i)"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <button class="btn  btn-theme Go_btn mt-2 mb-2" (click)="addExtraLength()">
                                                Add Length</button>
                                        </div>
                                    </div>
                                </div>

                            </div> -->

                            <!--  -->
                            <div class="mt40">
                                <div class="d-flex justify-content-between">
                                    <h3>Cost Price</h3>
                                    <div class="d-flex"><span class="base-price-cls">Base Price:</span>
                                        <input class="form-control" type="text" style="margin-bottom: 5px;"
                                            [(ngModel)]="basePriceCostPrice" [ngModelOptions]="{standalone: true}"
                                            (keypress)="commonService.restrictChar($event)"
                                            [maxlength]="maxLengthOfPriceFields">
                                    </div>
                                </div>
                                <div style="overflow:auto;">
                                    <table class="table table-bordered">
                                        <thead class="text-center">
                                            <tr>
                                                <th scope="col" rowspan="2">Price Type</th>
                                                <th scope="col" rowspan="2">Body</th>
                                                <th scope="col" rowspan="2">Diamond</th>
                                                <!-- <th scope="col" rowspan="2">MRP</th> -->
                                                <th *ngIf="embellishmentPriceHeader?.length"
                                                    [attr.colspan]="embellishmentPriceHeader?.length">
                                                    Embellishment</th>
                                                <th *ngIf="skirtPriceHeader?.length"
                                                    [attr.colspan]="skirtPriceHeader?.length * 2">Skirt</th>
                                                <th colspan="3">Size</th>
                                                <th rowspan="2">Standard Colors</th>
                                                <th rowspan="2">Premium Colors</th>
                                                <!-- <th scope="col" rowspan="2">Total</th> -->
                                            </tr>
                                            <!-- embellishment -->
                                            <tr>
                                                <ng-container *ngIf="embellishmentPriceHeader?.length">
                                                    <ng-container *ngFor="let embItem of embellishmentPriceHeader">
                                                        <th scope="col">
                                                            {{embItem?.embellishment_Type}}</th>
                                                    </ng-container>
                                                </ng-container>

                                                <!-- <th scope="col">Petal</th>
                                                        <th scope="col">Polka</th>
                                                        <th scope="col">Encrusted</th> -->
                                                <!-- </tr> -->

                                                <!-- skirt -->
                                                <!-- <tr> -->
                                                <ng-container *ngIf="skirtPriceHeader?.length">
                                                    <ng-container *ngFor="let skirtItem of skirtPriceHeader">
                                                        <th scope="col">{{skirtItem?.skirtName}}</th>
                                                        <th scope="col">Longer Length In {{skirtItem?.skirtName}}</th>
                                                    </ng-container>
                                                </ng-container>
                                                <!-- <th scope="col">GGT Skirt</th>
                                                                            <th scope="col">Longer Length in GGT</th>
                                                                            <th scope="col">Tulle Skirt</th>
                                                                            <th scope="col">Longer Length in Tulle</th>
                                                                            <th scope="col">Full Skirt</th>
                                                                            <th scope="col">Longer Length in Full Skirt</th> -->
                                                <!-- </tr> -->

                                                <!-- size -->
                                                <!-- <tr> -->
                                                <th scope="col">0-18</th>
                                                <th scope="col">20-24</th>
                                                <th scope="col">26-32</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <!-- <ng-template ngForOF let-item [ngFor]="collectionArray">
                                                                            <tr>
                                                                                <td>1200</td>
                                                                                <td>XYZ</td>
                                                                                <td>
                                                                                    <select>
                                                                                        <option value="">1</option>
                                                                                    </select>
                                                                                </td>
                                                                                <ng-template ngForOF let-emblishment [ngFor]="item.diamond">
                            
                                                                                    <td>{{emblishment.embellishmentName}}</td>
                                                                                </ng-template>
                                                                                <ng-template ngForOF let-skirt [ngFor]="item.skirt">
                                                                                    <td>{{skirt.skirtName}}</td>
                                                                                </ng-template>
                                                                                <ng-template ngForOF let-size [ngFor]="item.size">
                                                                                    <td>{{skirt.sizeName}}</td>
                                                                                </ng-template>
                                                                                <td>Stan</td>
                                                                                <td>premium</td>
                                                                                <td>total</td>
                                                                            </tr>
                                                                        </ng-template> -->
                                            <tr *ngFor="let item of bodyListCostPrice">
                                                <td>Cost Price</td>
                                                <td>
                                                    <div class="form-group  d-flex justify-content-between">
                                                        <select class="col-md-10 form-control custom-input"
                                                            [(ngModel)]="item.bodyId"
                                                            [ngModelOptions]="{standalone: true}" [disabled]="true">
                                                            <option value="">Select Body</option>
                                                            <option *ngFor="let data of bodyListArray"
                                                                [value]="data._id">
                                                                {{data?.bodyName}}</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-group  d-flex justify-content-between">
                                                        <select class="col-md-10 form-control custom-input"
                                                            [(ngModel)]="item.diamondId"
                                                            [ngModelOptions]="{standalone: true}">
                                                            <!-- <option value="">Select Diamond</option> -->
                                                            <option value=null>Select Diamond</option>
                                                            <option *ngFor="let data of diamondListArray"
                                                                [value]="data._id">
                                                                {{data?.diamondName}}</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <ng-container *ngIf="item?.embellishment?.length">

                                                    <ng-container *ngFor="let embItem of item?.embellishment">
                                                        <td>
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="embItem.price"
                                                                [ngModelOptions]="{standalone:true}"
                                                                (keypress)="commonService.restrictChar($event)"
                                                                [maxlength]="maxLengthOfPriceFields" />
                                                        </td>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="item?.skirt?.length">
                                                    <ng-container *ngFor="let skirtItem of item?.skirt">
                                                        <td>
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="skirtItem.price"
                                                                [ngModelOptions]="{standalone:true}"
                                                                (keypress)="commonService.restrictChar($event)"
                                                                [maxlength]="maxLengthOfPriceFields" />
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="skirtItem.extraLength"
                                                                [ngModelOptions]="{standalone:true}"
                                                                (keypress)="commonService.restrictChar($event)"
                                                                [maxlength]="maxLengthOfPriceFields" />
                                                        </td>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngFor="let sizeItem of item?.size">
                                                    <td>
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="sizeItem.price"
                                                            [ngModelOptions]="{standalone:true}"
                                                            (keypress)="commonService.restrictChar($event)"
                                                            [maxlength]="maxLengthOfPriceFields" />
                                                    </td>
                                                </ng-container>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="item.standardColor"
                                                        [ngModelOptions]="{standalone:true}"
                                                        (keypress)="commonService.restrictChar($event)"
                                                        [maxlength]="maxLengthOfPriceFields" />
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="item.premiumColor"
                                                        [ngModelOptions]="{standalone:true}"
                                                        (keypress)="commonService.restrictChar($event)"
                                                        [maxlength]="maxLengthOfPriceFields" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--  -->

                            <!--  -->
                            <div class="mt40">
                                <div class="d-flex justify-content-between">
                                    <h3>Whole Sale Price</h3>
                                    <div class="d-flex"><span class="base-price-cls">Base Price:</span>
                                        <input class="form-control" type="text" style="margin-bottom: 5px;"
                                            [(ngModel)]="basePriceWholeSale" [ngModelOptions]="{standalone: true}"
                                            (keypress)="commonService.restrictChar($event)"
                                            [maxlength]="maxLengthOfPriceFields">
                                    </div>
                                </div>
                                <div style="overflow:auto;">
                                    <table class="table table-bordered">
                                        <thead class="text-center">
                                            <tr>
                                                <th scope="col" rowspan="2">Price Type</th>
                                                <th scope="col" rowspan="2">Body</th>
                                                <th scope="col" rowspan="2">Diamond</th>
                                                <!-- <th scope="col" rowspan="2">MRP</th> -->
                                                <th *ngIf="embellishmentPriceHeader?.length"
                                                    [attr.colspan]="embellishmentPriceHeader?.length">
                                                    Embellishment</th>
                                                <th *ngIf="skirtPriceHeader?.length"
                                                    [attr.colspan]="skirtPriceHeader?.length * 2">Skirt</th>
                                                <th colspan="3">Size</th>
                                                <th rowspan="2">Standard Colors</th>
                                                <th rowspan="2">Premium Colors</th>
                                                <!-- <th scope="col" rowspan="2">Total</th> -->
                                            </tr>
                                            <!-- embellishment -->
                                            <tr>
                                                <ng-container *ngIf="embellishmentPriceHeader?.length">
                                                    <ng-container *ngFor="let embItem of embellishmentPriceHeader">
                                                        <th scope="col">
                                                            {{embItem?.embellishment_Type}}</th>
                                                    </ng-container>
                                                </ng-container>

                                                <!-- <th scope="col">Petal</th>
                            <th scope="col">Polka</th>
                            <th scope="col">Encrusted</th> -->
                                                <!-- </tr> -->

                                                <!-- skirt -->
                                                <!-- <tr> -->
                                                <ng-container *ngIf="skirtPriceHeader?.length">
                                                    <ng-container *ngFor="let skirtItem of skirtPriceHeader">
                                                        <th scope="col">{{skirtItem?.skirtName}}</th>
                                                        <th scope="col">Longer Length In {{skirtItem?.skirtName}}</th>
                                                    </ng-container>
                                                </ng-container>
                                                <!-- <th scope="col">GGT Skirt</th>
                                                <th scope="col">Longer Length in GGT</th>
                                                <th scope="col">Tulle Skirt</th>
                                                <th scope="col">Longer Length in Tulle</th>
                                                <th scope="col">Full Skirt</th>
                                                <th scope="col">Longer Length in Full Skirt</th> -->
                                                <!-- </tr> -->

                                                <!-- size -->
                                                <!-- <tr> -->
                                                <th scope="col">0-18</th>
                                                <th scope="col">20-24</th>
                                                <th scope="col">26-32</th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            <tr *ngFor="let item of bodyListWholeSale">
                                                <td>Whole Sale Price</td>
                                                <td>
                                                    <div class="form-group  d-flex justify-content-between">
                                                        <select class="col-md-10 form-control custom-input"
                                                            [(ngModel)]="item.bodyId"
                                                            [ngModelOptions]="{standalone: true}" [disabled]="true">
                                                            <option value="">Select Body</option>
                                                            <option *ngFor="let data of bodyListArray"
                                                                [value]="data._id">
                                                                {{data?.bodyName}}</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-group  d-flex justify-content-between">
                                                        <select class="col-md-10 form-control custom-input"
                                                            [(ngModel)]="item.diamondId"
                                                            [ngModelOptions]="{standalone: true}">
                                                            <option value=null>Select Diamond</option>
                                                            <option *ngFor="let data of diamondListArray"
                                                                [value]="data._id">
                                                                {{data?.diamondName}}</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <ng-container *ngIf="item?.embellishment?.length">

                                                    <ng-container *ngFor="let embItem of item?.embellishment">
                                                        <td>
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="embItem.price"
                                                                [ngModelOptions]="{standalone:true}"
                                                                (keypress)="commonService.restrictChar($event)"
                                                                [maxlength]="maxLengthOfPriceFields" />
                                                        </td>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="item?.skirt?.length">
                                                    <ng-container *ngFor="let skirtItem of item?.skirt">
                                                        <td>
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="skirtItem.price"
                                                                [ngModelOptions]="{standalone:true}"
                                                                (keypress)="commonService.restrictChar($event)"
                                                                [maxlength]="maxLengthOfPriceFields" />
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="skirtItem.extraLength"
                                                                [ngModelOptions]="{standalone:true}"
                                                                (keypress)="commonService.restrictChar($event)"
                                                                [maxlength]="maxLengthOfPriceFields" />
                                                        </td>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngFor="let sizeItem of item?.size">
                                                    <td>
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="sizeItem.price"
                                                            [ngModelOptions]="{standalone:true}"
                                                            (keypress)="commonService.restrictChar($event)"
                                                            [maxlength]="maxLengthOfPriceFields" />
                                                    </td>
                                                </ng-container>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="item.standardColor"
                                                        [ngModelOptions]="{standalone:true}"
                                                        (keypress)="commonService.restrictChar($event)"
                                                        [maxlength]="maxLengthOfPriceFields" />
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="item.premiumColor"
                                                        [ngModelOptions]="{standalone:true}"
                                                        (keypress)="commonService.restrictChar($event)"
                                                        [maxlength]="maxLengthOfPriceFields" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--  -->

                            <!--  -->
                            <div class="mt40">
                                <div class="d-flex justify-content-between">
                                    <h3>MRP</h3>
                                    <div class="d-flex"><span class="base-price-cls">Base Price:</span>
                                        <input class="form-control" type="text" style="margin-bottom: 5px;"
                                            [(ngModel)]="basePriceMRP" [ngModelOptions]="{standalone: true}"
                                            (keypress)="commonService.restrictChar($event)"
                                            [maxlength]="maxLengthOfPriceFields">
                                    </div>
                                </div>
                                <div style="overflow:auto;">
                                    <table class="table table-bordered">
                                        <thead class="text-center">
                                            <tr>
                                                <th scope="col" rowspan="2">Price Type</th>
                                                <th scope="col" rowspan="2">Body</th>
                                                <th scope="col" rowspan="2">Diamond</th>
                                                <!-- <th scope="col" rowspan="2">MRP</th> -->
                                                <th *ngIf="embellishmentPriceHeader?.length"
                                                    [attr.colspan]="embellishmentPriceHeader?.length">
                                                    Embellishment</th>
                                                <th *ngIf="skirtPriceHeader?.length"
                                                    [attr.colspan]="skirtPriceHeader?.length * 2">Skirt</th>
                                                <th colspan="3">Size</th>
                                                <th rowspan="2">Standard Colors</th>
                                                <th rowspan="2">Premium Colors</th>
                                                <!-- <th scope="col" rowspan="2">Total</th> -->
                                            </tr>
                                            <!-- embellishment -->
                                            <tr>
                                                <ng-container *ngIf="embellishmentPriceHeader?.length">
                                                    <ng-container *ngFor="let embItem of embellishmentPriceHeader">
                                                        <th scope="col">
                                                            {{embItem?.embellishment_Type}}</th>
                                                    </ng-container>
                                                </ng-container>

                                                <!-- <th scope="col">Petal</th>
                                                        <th scope="col">Polka</th>
                                                        <th scope="col">Encrusted</th> -->
                                                <!-- </tr> -->

                                                <!-- skirt -->
                                                <!-- <tr> -->
                                                <ng-container *ngIf="skirtPriceHeader?.length">
                                                    <ng-container *ngFor="let skirtItem of skirtPriceHeader">
                                                        <th scope="col">{{skirtItem?.skirtName}}</th>
                                                        <th scope="col">Longer Length In {{skirtItem?.skirtName}}</th>
                                                    </ng-container>
                                                </ng-container>
                                                <!-- <th scope="col">GGT Skirt</th>
                                                                            <th scope="col">Longer Length in GGT</th>
                                                                            <th scope="col">Tulle Skirt</th>
                                                                            <th scope="col">Longer Length in Tulle</th>
                                                                            <th scope="col">Full Skirt</th>
                                                                            <th scope="col">Longer Length in Full Skirt</th> -->
                                                <!-- </tr> -->

                                                <!-- size -->
                                                <!-- <tr> -->
                                                <th scope="col">0-18</th>
                                                <th scope="col">20-24</th>
                                                <th scope="col">26-32</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr *ngFor="let item of bodyListMRP">
                                                <td>MRP</td>
                                                <td>
                                                    <div class="form-group  d-flex justify-content-between">
                                                        <select class="col-md-10 form-control custom-input"
                                                            [(ngModel)]="item.bodyId"
                                                            [ngModelOptions]="{standalone: true}" [disabled]="true">
                                                            <option value="">Select Body</option>
                                                            <option *ngFor="let data of bodyListArray"
                                                                [value]="data._id">
                                                                {{data?.bodyName}}</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-group  d-flex justify-content-between">
                                                        <select class="col-md-10 form-control custom-input"
                                                            [(ngModel)]="item.diamondId"
                                                            [ngModelOptions]="{standalone: true}">
                                                            <option value=null>Select Diamond</option>
                                                            <option *ngFor="let data of diamondListArray"
                                                                [value]="data._id">
                                                                {{data?.diamondName}}</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <ng-container *ngIf="item?.embellishment?.length">

                                                    <ng-container *ngFor="let embItem of item?.embellishment">
                                                        <td>
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="embItem.price"
                                                                [ngModelOptions]="{standalone:true}"
                                                                (keypress)="commonService.restrictChar($event)"
                                                                [maxlength]="maxLengthOfPriceFields" />
                                                        </td>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="item?.skirt?.length">
                                                    <ng-container *ngFor="let skirtItem of item?.skirt">
                                                        <td>
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="skirtItem.price"
                                                                [ngModelOptions]="{standalone:true}"
                                                                (keypress)="commonService.restrictChar($event)"
                                                                [maxlength]="maxLengthOfPriceFields" />
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="skirtItem.extraLength"
                                                                [ngModelOptions]="{standalone:true}"
                                                                (keypress)="commonService.restrictChar($event)"
                                                                [maxlength]="maxLengthOfPriceFields" />
                                                        </td>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngFor="let sizeItem of item?.size">
                                                    <td>
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="sizeItem.price"
                                                            [ngModelOptions]="{standalone:true}"
                                                            (keypress)="commonService.restrictChar($event)"
                                                            [maxlength]="maxLengthOfPriceFields" />
                                                    </td>
                                                </ng-container>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="item.standardColor"
                                                        [ngModelOptions]="{standalone:true}"
                                                        (keypress)="commonService.restrictChar($event)"
                                                        [maxlength]="maxLengthOfPriceFields" />
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="item.premiumColor"
                                                        [ngModelOptions]="{standalone:true}"
                                                        (keypress)="commonService.restrictChar($event)"
                                                        [maxlength]="maxLengthOfPriceFields" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--  -->

                        </form>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme"
                            routerLink="/collection-management/list-collection">Back</button>
                        <button class="btn btn-large  max-WT-150 btn-theme" (click)="editCollection()">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>