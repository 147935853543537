<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Tax Management</h1>
        </div>
        <div class="">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="  mb40">
                    <div class="add-store-block input-style mb20 mt20 view-label-align">
                        <!-- <form [formGroup]="addSwatchesForm" #f="ngForm"> -->
                        <!-- <div class="form-group row align-items-baseline">
                            <label class="col-md-5 textalignright ">Tax Price:</label>
                        </div> -->
                        <div class="form-group row align-items-baseline">

                            <table class="table table-bordered">
                                <thead class="text-center">
                                    <tr>
                                        <th scope="col">Country</th>
                                        <th scope="col">Price %</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr>
                                        <th>USA (In $)</th>
                                        <td><input type="text" class="form-control" [(ngModel)]="taxDetails.USA"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" /></td>
                                    </tr>
                                    <tr>
                                        <th>UK (In £)</th>
                                        <td><input type="text" class="form-control" [(ngModel)]="taxDetails.UK"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" /></td>
                                    </tr>
                                    <tr>
                                        <th>Europe (In €)</th>
                                        <td><input type="text" class="form-control" [(ngModel)]="taxDetails.EUR"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" /></td>
                                    </tr>
                                    <tr>
                                        <th>Other (In £)</th>
                                        <td><input type="text" class="form-control" [(ngModel)]="taxDetails.OTHER"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" /></td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- </form> -->
                    </div>
                    <div class="text-center mt40 mb40">
                        <!-- <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/skirt-management/list-skirt">Back</button> -->
                        <button class="btn btn-large  max-WT-150 btn-theme" (click)="updateTax()">Update Tax</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>


<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Shipping Management</h1>
        </div>
        <div class="">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="  mb40">
                    <div class="add-store-block input-style mb20 mt20 view-label-align">
                        <!-- <form [formGroup]="addSwatchesForm" #f="ngForm"> -->
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5 textalignright ">Shipping Price(Within Country) :</label>
                            <div class="col-md-7 textaligncenter">
                                <div class="form-group">
                                    <input type="text" class="form-control"
                                        placeholder="{{validationMessage?.shippingCharge.placeholder}}(1$/Km)"
                                        [(ngModel)]="shippingDetails.shippingPriceWithin"
                                        (keypress)="commonService.restrictChar($event)"
                                        [maxlength]="maxLengthOfShippingFields">
                                    <div *ngIf="(skirt.invalid ) && skirt.touched || skirt.dirty">
                                        <small *ngIf="skirt.errors?.required"
                                            class="text-danger">{{validationMessage?.shippingCharge.required}}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5 textalignright "> Shipping Price (Outside Country):</label>
                        </div>
                        <div class="form-group row align-items-baseline">

                            <table class="table table-bordered">
                                <thead class="text-center">
                                    <tr>
                                        <th scope="col">Country</th>
                                        <th scope="col">Price</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr>
                                        <th>USA (In $)</th>
                                        <td><input type="text" class="form-control" placeholder="Price"
                                                [(ngModel)]="shippingDetails.shippingPriceOutside.USA"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfShippingFields" /></td>
                                    </tr>
                                    <tr>
                                        <th>UK (In £)</th>
                                        <td><input type="text" class="form-control" placeholder="Price"
                                                [(ngModel)]="shippingDetails.shippingPriceOutside.UK"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfShippingFields" /></td>
                                    </tr>
                                    <tr>
                                        <th>Europe (In €)</th>
                                        <td><input type="text" class="form-control" placeholder="Price"
                                                [(ngModel)]="shippingDetails.shippingPriceOutside.EUR"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfShippingFields" /></td>
                                    </tr>
                                    <tr>
                                        <th>Other (In £)</th>
                                        <td><input type="text" class="form-control" placeholder="Price"
                                                [(ngModel)]="shippingDetails.shippingPriceOutside.OTHER"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfShippingFields" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5 textalignright "> Rush Price <span>:</span></label>
                        </div>
                        <div class="form-group row align-items-baseline">

                            <table class="table table-bordered">
                                <thead class="text-center">
                                    <tr>
                                        <th scope="col" rowspan="2">Country</th>
                                        <th scope="col" colspan="3">Days</th>
                                    </tr>
                                    <tr>
                                        <td>10-20</td>
                                        <td>20-30</td>
                                        <td>30-60</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of shippingDetails?.rushFee | keyvalue">
                                        <th>{{item?.key}}</th>
                                        <th *ngFor="let subItem of item?.value | keyvalue">
                                            <input type="text" class="form-control" placeholder="Price"
                                                [(ngModel)]="subItem.value.price"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfShippingFields" />
                                            <!-- {{subItem?.value?.price}} -->
                                        </th>
                                        <!-- <td><input type="text" class="form-control"
                                                [(ngModel)]="shippingDetails.rushFee.USA.price"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" /></td>
                                        <td><input type="text" class="form-control"
                                                [(ngModel)]="shippingDetails.rushFee.USA.days"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" /></td> -->
                                    </tr>
                                    <!-- <tr>
                                        <th>USA (In $)</th>
                                        <td><input type="text" class="form-control"
                                                [(ngModel)]="shippingDetails.rushFee.USA.price"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" /></td>
                                        <td><input type="text" class="form-control"
                                                [(ngModel)]="shippingDetails.rushFee.USA.days"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" /></td>

                                    </tr>
                                    <tr>
                                        <th>UK (In £)</th>
                                        <td><input type="text" class="form-control"
                                                [(ngModel)]="shippingDetails.rushFee.UK.price"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" /></td>
                                        <td><input type="text" class="form-control"
                                                [(ngModel)]="shippingDetails.rushFee.UK.days"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" /></td>

                                    </tr>
                                    <tr>
                                        <th>Europe (In )</th>
                                        <td><input type="text" class="form-control"
                                                [(ngModel)]="shippingDetails.rushFee.EUROPE.price"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" /></td>
                                        <td><input type="text" class="form-control"
                                                [(ngModel)]="shippingDetails.rushFee.EUROPE.days"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" /></td>

                                    </tr>
                                    <tr>
                                        <th>Other (In )</th>
                                        <td><input type="text" class="form-control"
                                                [(ngModel)]="shippingDetails.rushFee.OTHER.price"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" /></td>
                                        <td><input type="text" class="form-control"
                                                [(ngModel)]="shippingDetails.rushFee.OTHER.days"
                                                (keypress)="commonService.restrictChar($event)"
                                                [maxlength]="maxLengthOfPriceFields" /></td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                        <!-- </form> -->
                    </div>
                    <div class="text-center mt40 mb40">
                        <!-- <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/skirt-management/list-skirt">Back</button> -->
                        <button class="btn btn-large  max-WT-150 btn-theme" (click)="updateShipping()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
<div class="wrapper" style="margin-bottom: 5px;">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">VAT Management</h1>
        </div>
        <div class="">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="row justify-content-end">
                    <button class="btn" [routerLink]="['/tax-shipping-management/vat-list']" style="color: #000;">Vat
                        Details</button>
                </div>
                <div class="  mb40">
                    <div class="add-store-block input-style mb20 mt20 view-label-align">
                        <form [formGroup]="addTrunkForm" #f="ngForm">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-12 textalignright ">Country For VAT / Sales tax purposes:</label>
                                <div class="col-md-12 textaligncenter mt-1">
                                    <div class="form-group">
                                        <select class="form-control" formControlName="country"
                                            style="outline: none; box-shadow: none;" (change)="countryName($event)">
                                            <option value="" selected disabled>Select Country</option>
                                            <option *ngFor="let item of listCountry" [value]="item?.countryName">
                                                {{item?.countryName}}</option>
                                        </select>
                                        <div *ngIf="(country.invalid ) && country.touched || country.dirty">
                                            <small *ngIf="country.errors?.required"
                                                class="text-danger">{{validationMessage?.country.required}}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-12 textalignright ">Do you need to charge VAT?:</label>
                                <div class="col-md-12 textaligncenter mt-1">
                                    <div class="form-group">
                                        <select class="form-control" (change)="vatStatus($event)"
                                            formControlName="vStatus" style="outline: none; box-shadow: none;">
                                            <option value="" selected disabled>Select</option>
                                            <option value="YES">Yes</option>
                                            <option value="NO">No</option>
                                        </select>
                                        <div *ngIf="(vStatus.invalid ) && vStatus.touched || vStatus.dirty">
                                            <small *ngIf="vStatus.errors?.required"
                                                class="text-danger">{{validationMessage?.vStatus.required}}
                                            </small>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="form-group row align-items-baseline" *ngIf="vatFeild == 'YES' ">
                                <label class="col-md-12 textalignright " style="justify-content: start;">VAT Number
                                    <span>(if applicable):</span></label>
                                <div class="col-md-12 textaligncenter mt-1">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter VAT Number"
                                            formControlName="vatNum" [(ngModel)]="vatNumber"
                                            [ngModelOptions]="{standalone: true}"
                                            (keypress)="commonService.restrictChar($event)">
                                        <div *ngIf="(vatNum.invalid ) && vatNum.touched || vatNum.dirty">
                                            <small *ngIf="vatNum.errors?.required"
                                                class="text-danger">{{validationMessage?.vatNum.required}}
                                            </small>
                                        </div>
                                        <!-- <div *ngIf="(skirt.invalid ) && skirt.touched || skirt.dirty">
                                            <small *ngIf="skirt.errors?.required"
                                                class="text-danger">{{validationMessage?.shippingCharge.required}}
                                            </small>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">VAT Rates:</label>
                            </div>
                            <div class="form-group row align-items-baseline">

                                <table class="table table-bordered">
                                    <thead class="text-center">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Rate(%)</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of vatTax;let i = index">
                                            <tr>
                                                <th><input type="text" class="form-control" [(ngModel)]="item.taxName"
                                                        [ngModelOptions]="{standalone: true}">
                                                </th>
                                                <td><input type="text" class="form-control" placeholder="%"
                                                        [(ngModel)]="item.rate" [ngModelOptions]="{standalone: true}"
                                                        (keypress)="commonService.restrictChar($event)" /></td>
                                                <td>
                                                    <p (click)="removeVatFeildi(i)" class="crossBtn"><i
                                                            class="fa fa-times" aria-hidden="true"></i></p>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                                <p><button class="vatBtn" (click)="addTax()">+ VAT Rate</button></p>
                            </div>
                            <div class="text-center mt40 mb40">
                                <!-- <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/skirt-management/list-skirt">Back</button> -->
                                <button class="btn btn-large  max-WT-150 btn-theme" (click)="addVat()"
                                    [disabled]="!addTrunkForm.valid">Save</button>
                            </div>
                        </form> 
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>