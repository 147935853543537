<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Arm / Add</h1>
        </div>
        <div class="content-section">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="  mb40">
                    <div class="add-store-block input-style mb20 mt20 view-label-align">
                        <form [formGroup]="addArmForm" #f="ngForm">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">Arm Name <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="{{validationMessage?.arm.placeholder}}"
                                            formControlName="arm" (keypress)="commonService.preventSpace($event)"
                                            maxlength="60">
                                            <div *ngIf="(arm.invalid ) && arm.touched || arm.dirty">
                                                <small *ngIf="arm.errors?.required"
                                                    class="text-danger">{{validationMessage?.arm.required}} </small>
                                                <small *ngIf="arm.errors?.pattern"
                                                    class="text-danger">{{validationMessage?.arm.valid}}</small>
                                                    <small *ngIf="arm.errors?.minlength"
                                                    class="text-danger">{{validationMessage?.arm.minLength}}</small>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">Arm Image <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group ">
                                        <input type="file" formControlName="image" name="image"
                                            accept="image/jpg,image/jpeg,image/png" id="image"
                                            (change)="uploadImg($event)">

                                        <img *ngIf="imageUrl" [src]="imageUrl?.webImage" class="mt-1" alt="">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/arm-management/list-arm">Back</button>
                        <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="!addArmForm.valid"
                            (click)="addArm()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>