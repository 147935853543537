import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/provider/common.service';
import { Router } from '@angular/router';
import { RegEx, ValidationMessage } from 'src/app/config/validation-message';
@Component({
  selector: 'app-add-tax-shipping',
  templateUrl: './add-tax-shipping.component.html',
  styleUrls: ['./add-tax-shipping.component.scss']
})
export class AddTaxShippingComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  validationMessage = ValidationMessage; //global validation code
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  vatNumber: any
  vatTax = [{ taxName: '', rate: '' }]
  addTrunkForm: FormGroup

  // taxDetails: any;
  maxLengthOfPriceFields: number = 2;
  // shippingDetails: any;
  maxLengthOfShippingFields: number = 10;

  taxDetails: any = {
    // "tax": {
    "USA": Number(0),
    "UK": Number(0),
    "EUR": Number(0),
    "OTHER": Number(0)
    // }
  }

  shippingDetails: any = {
    rushFee: {
      USA: { price: 0, days: "0" },
      UK: { price: 0, days: "0" },
      EUROPE: { price: 0, days: "0" },
      OTHER: { price: 0, days: "0" },
    },
    shippingPriceOutside: {
      USA: 0,
      UK: 0,
      EUR: 0,
      OTHER: 0
    },
    shippingPriceWithin: 0
  }
  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.addColorFormValidation();
    this.addSwatchesFormValidation();
    // this.getColorList();
    this.getTaxDetails();;
    this.getShippingDetails();
    this.countryList();
  }

  // vat functions
  vatFeild: string
  vatStatus(event) {
    this.vatFeild = event.target.value
    this.setValidators(this.vatFeild)
  }

  addTax() {
    this.vatTax.push({ taxName: '', rate: '' })
  }

  removeVatFeildi(i) {
    this.vatTax.splice(i, 1)
  }

  // vat end

  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      // 'colorName': new FormControl('', [Validators.required]),
      'skirt': new FormControl('', [Validators.required, Validators.pattern(RegEx.name)]),
      // 'description': new FormControl('',[Validators.required]),
      // 'file': new FormControl(''),
      'image': new FormControl('')

    })
  }

  addColorFormValidation() {
    this.addTrunkForm = new FormGroup({
      'country': new FormControl('', [Validators.required]),
      'vStatus': new FormControl('', [Validators.required]),
      'vatNum': new FormControl('', [Validators.required]),
    })
  }


  /**
   * @call validation 
   */
  get colorName() { return this.addSwatchesForm.get('colorName') }
  get skirt() { return this.addSwatchesForm.get('skirt') }
  get description() { return this.addSwatchesForm.get('description') }

  get price() { return this.addSwatchesForm.get('price') }
  get quantity() { return this.addSwatchesForm.get('quantity') }
  get country() { return this.addTrunkForm.get('country') }
  get vStatus() { return this.addTrunkForm.get('vStatus') }
  get vatNum() { return this.addTrunkForm.get('vatNum') }



  setValidators(e) {
    this.addTrunkForm.get('vatNum').reset()
    this.addTrunkForm.get('vatNum').clearValidators();

    if (e == 'YES') {
      let a = this.addTrunkForm.get('vatNum')
      a.setValidators([Validators.required])
    }
    else {
      this.addTrunkForm.get('vatNum').clearValidators();
      this.addTrunkForm.get('vatNum').reset()
    }
    this.addTrunkForm.controls['vatNum'].updateValueAndValidity();
  }


  addSwatches() {
    console.log(this.colorArray);
    // this.router.navigate(['/swatches/list-swatches'])
    let data = {
      'skirtName': this.addSwatchesForm.value.skirt,
      'colors': this.colorArray,
      // 'description': this.addSwatchesForm.value.description,
      // 'quantity': this.addSwatchesForm.value.quantity,
      // 'rawImage' : this.imageUrl
    }
    this.commonService.showSpinner();
    this.commonService.postApi(ApiEndPoint.skirtAdd, data, 1).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.responseCode == 200) {
        this.commonService.successToast(res.responseMessage);
        this.router.navigate(['/skirt-management/list-skirt'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  // -------------------- get category list --------------------- //
  getColorList() {
    let apiReqUrl: any = ApiEndPoint.colorsList
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      // console.log("get category management list response ==>", res)
      if (res.responseCode == 200) {
        this.colorListArray = res.result ? res.result : '';
        this.totalItems = res.result.total
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      } else {
        this.colorListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  uploadImg(event): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi(ApiEndPoint.uploadFile, fb, 1).subscribe((res: any) => {
      console.log(res);
      if (res.responseCode == 200) {
        // this.imageUrl = {webImage : res['result']['webImage'], mobileImage : res['result']['mobileImage'] }
        this.imageUrl = res['result']
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();

      }
    })
  }


  async uploadColorFunc(img, i) {
    var fb = new FormData();
    fb.append('file', img.target.files[0])
    this.commonService.showSpinner();
    let respose = await this.commonService.postFormDataApi(ApiEndPoint.uploadFile, fb, 1).toPromise()
    console.log(this.colorArray);
    this.commonService.hideSpinner();

    this.colorArray[i] = {
      'colorId': this.colorArray[i]['colorId'],
      'image': { webImage: respose['result']['webImage'], mobileImage: respose['result']['mobileImage'] }
    }

  }
  colorFieldArray: any = [1]
  colorArray: any = []
  addColour() {
    this.colorFieldArray.push(1)
  }
  showImage(event, i) {

    this.colorArray[i] = {
      'colorId': event.target.value,
      'image': ''
    }
    console.log(this.showImageField[i]);

    this.showImageField[i] = true;
    console.log(this.showImageField[i]);

  }

  /**
   * ========================== tax functionality section
   */
  getTaxDetails() {
    let apiReqUrl = ApiEndPoint.viewTax
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log(res);
      if (res.responseCode == 200) {
        this.taxDetails = res.result;
      }
    })
  }

  updateTax() {
    if (!this.taxDetails.USA || !this.taxDetails.UK || !this.taxDetails.EUR || !this.taxDetails.OTHER) {
      return this.commonService.infoToast('Fields are required.')
    }
    let apiReqUrl = `${ApiEndPoint.updateTax + '?_id=' + this.taxDetails._id}`;
    let apiReqData = {
      // "tax": {
      //   "USA": Number(this.taxDetails.tax.USA),
      //   "UK": Number(this.taxDetails.tax.UK),
      //   "EUROPE": Number(this.taxDetails.tax.EUROPE),
      //   "OTHER": Number(this.taxDetails.tax.OTHER)
      // }
      // "tax": {
      "USA": Number(this.taxDetails.USA),
      "UK": Number(this.taxDetails.UK),
      "EUR": Number(this.taxDetails.EUR),
      "OTHER": Number(this.taxDetails.OTHER)
      // }
    }
    console.log(apiReqData);
    this.commonService.putApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      console.log(res);
      if (res.responseCode == 200) {
        this.commonService.successToast('Tax percentage updated successfully.')
      }
    })
  }

  /**
   * =========================== shipping functionlaity section
   */

  getShippingDetails() {
    let apiReqUrl = ApiEndPoint.viewShippingCharge
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log(res);
      if (res.responseCode == 200) {
        this.shippingDetails = res.result;
      }
    })
  }

  updateShipping() {
    // validation
    if (!this.shippingDetails.shippingPriceWithin) {
      return this.commonService.infoToast('Fields are required.')
    } else if (!this.shippingDetails.shippingPriceOutside.USA || !this.shippingDetails.shippingPriceOutside.UK || !this.shippingDetails.shippingPriceOutside.EUR || !this.shippingDetails.shippingPriceOutside.OTHER) {
      return this.commonService.infoToast('Fields are required.')
    }
    // end validation
    let apiReqUrl = `${ApiEndPoint.updateShippingCharge + '?_id=' + this.shippingDetails._id}`
    let apiReqData: any = {
      rushFee: this.shippingDetails?.rushFee,
      // "rushFee": {
      //   "USA": [
      //     {
      //       "price": 10,
      //       "days": "10-20"
      //     },
      //     {
      //       "price": 20,
      //       "days": "20-30"
      //     },
      //     {
      //       "price": 30,
      //       "days": "30-60"
      //     }
      //   ],
      //   "UK": [
      //     {
      //       "price": 10,
      //       "days": "10-20"
      //     },
      //     {
      //       "price": 20,
      //       "days": "20-30"
      //     },
      //     {
      //       "price": 30,
      //       "days": "30-60"
      //     }
      //   ],
      //   "EUROPE": [
      //     {
      //       "price": 10,
      //       "days": "10-20"
      //     },
      //     {
      //       "price": 20,
      //       "days": "20-30"
      //     },
      //     {
      //       "price": 30,
      //       "days": "30-60"
      //     }
      //   ],
      //   "OTHER": [
      //     {
      //       "price": 10,
      //       "days": "10-20"
      //     },
      //     {
      //       "price": 20,
      //       "days": "20-30"
      //     },
      //     {
      //       "price": 30,
      //       "days": "30-60"
      //     }
      //   ]
      // },
      // rushFee: {
      //   USA: {
      //     price: Number(this.shippingDetails.rushFee.USA.price),
      //     days: Number(this.shippingDetails.rushFee.USA.days)
      //   },
      //   UK: {
      //     price: Number(this.shippingDetails.rushFee.UK.price),
      //     days: Number(this.shippingDetails.rushFee.UK.days)
      //   },
      //   EUROPE: {
      //     price: Number(this.shippingDetails.rushFee.EUROPE.price),
      //     days: Number(this.shippingDetails.rushFee.EUROPE.days)
      //   },
      //   OTHER: {
      //     price: Number(this.shippingDetails.rushFee.OTHER.price),
      //     days: Number(this.shippingDetails.rushFee.OTHER.days)
      //   }
      // },
      shippingPriceOutside: {
        USA: Number(this.shippingDetails.shippingPriceOutside.USA),
        UK: Number(this.shippingDetails.shippingPriceOutside.UK),
        EUR: Number(this.shippingDetails.shippingPriceOutside.EUR),
        OTHER: Number(this.shippingDetails.shippingPriceOutside.OTHER)
      },
      shippingPriceWithin: Number(this.shippingDetails.shippingPriceWithin)
    }
    console.log(apiReqData);

    this.commonService.putApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      console.log(res);
      if (res.responseCode == 200) {
        this.commonService.successToast('Shipping charges updated successfully.')
      }
    })
  }

  checkProperties(obj) {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] != "")
        return false;
    }
    return true;
  }

  // Vat management Functionalities and apis
  listCountry: any
  countryList() {
    let url = ApiEndPoint.countryList;
    this.commonService.getApi(url, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.listCountry = res.result;
      }
    })
  }
  nameOfCountry: any
  countryName(event) {
    this.nameOfCountry = event.target.value;
  }

  addVat() {
    let url = ApiEndPoint.addVat;
    let isValid = true
    this.vatTax.forEach((element) => {
      if (!element.taxName || !element.rate) {
        isValid = false
        return
      }
    })
    if (!isValid) {
      return
    }
    let vatRateArray = []
    for (let item of this.vatTax) {
      let object = {
        "name": item.taxName,
        "rates": item.rate
      }
      vatRateArray.push(object)
      console.log(vatRateArray);

    }
    const data = {
      country: this.nameOfCountry,
      doYouNeedToChargeVat: this.vatFeild,
      vatNumber: this.vatNumber,
      vatRates: vatRateArray
    }
    this.commonService.showSpinner();
    this.commonService.postApi(url, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    }, err => {
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.responseMessage);
    })
  }


}

