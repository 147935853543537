import { ExcelService } from 'src/app/provider/excel.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { ValidationMessage } from 'src/app/config/validation-message';

declare var $: any;

@Component({
  selector: 'app-list-collection',
  templateUrl: './list-collection.component.html',
  styleUrls: ['./list-collection.component.scss']
})
export class ListCollectionComponent implements OnInit {

  searchForm: FormGroup;
  collectionListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;

  categoryId: any;
  validationMessage = ValidationMessage;
  today = new Date().toISOString().split('T')[0]


  constructor(private router: Router, public commonService: CommonService, public excelService: ExcelService) { }

  ngOnInit() {
    this.searchFormValidation();
    this.getCollectionList();
    console.log(this.today)
  }

  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.getCollectionList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        fromDate: '',
        toDate: ''
      });
      this.getCollectionList()
    }
  }

  // -------------------- get category list --------------------- //
  getCollectionList() {
    // let apiReqUrl: any = `${ApiEndPoint.categoryList}`
    let apiReqUrl: any = 'product/collectionWithPagination'
    this.commonService.showSpinner();
    let apiReqData: any = {
      page: this.currentPage,
      limit: this.itemPerPage,
      search: this.searchForm.value.search,
      fromDate: this.searchForm.value.fromDate ? new Date(this.searchForm.value.fromDate).toISOString() : '',
      toDate: this.searchForm.value.toDate ? new Date(this.searchForm.value.toDate).toISOString() : ''
    }
    // remove empty keys from request body
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.responseCode == 200) {
        this.collectionListArray = res.result.docs ? res.result.docs : '';
        this.commonService.hideSpinner();

      } else {
        this.collectionListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      this.collectionListArray = []
      this.commonService.errorToast(err.responseMessage)
      this.commonService.hideSpinner();

    })
  }

  pagination(event) {
    console.log(event)
    this.currentPage = event;
    this.getCollectionList()
  }

  addCategory() {
    this.router.navigate(['/collection-management/add-collection'])
  }
  // view category
  viewCategory(categoryId) {
    this.router.navigate(['/collection-management/view-collection'], { queryParams: { categoryId: categoryId } })
  }

  editCategory(categoryId) {
    this.router.navigate(['/collection-management/edit-collection'], { queryParams: { categoryId: categoryId } })
  }

  // ------------------------------- delete hospital ----------------------------- //
  deleteCollectionConfirmationModal(categoryId) {
    $('#deleteCollectionConfirmationModalId').modal('show')
    this.categoryId = categoryId
  }
  deleteCollectionModal() {
    $('#deleteCollectionConfirmationModalId').modal('hide')
    $('#deleteCollection').modal('show')
  }
  deleteCollection() {
    let apiReqUrl: any = `product/deleteCollection?_id=` + this.categoryId
    this.commonService.showSpinner();
    this.commonService.deleteApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      console.log("delete category user response ==>", res)
      $('#deleteCollection').modal('hide');
      if (res.responseCode == 200) {
        this.getCollectionList()
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.collectionListArray.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "Collection Name": element.collectionName ? element.collectionName : 'N/A',
        "Created At": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
      })
      // let fhkds= JSON.stringify(dataArr)
    })

    this.excelService.exportAsExcelFile(dataArr, 'Collection Category list');
  }


}
