import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  resetPassword: FormGroup;
  // userId:any;
  token:any;
  email: any;

  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, public router: Router) { 
    this.activatedroute.queryParams.subscribe((res)=>{
      this.email = res.email
    })
  }

  ngOnInit() {
    this.resetPassword = new FormGroup({
      "password": new FormControl('', ([Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/)])),
      "confirmPassword": new FormControl('', [Validators.required])
    });
    // this.activatedroute.params.subscribe((res) => {
    //   console.log(res)
    //   this.email = res.email
    // })
  }

  resetApi() {
    if (this.resetPassword.valid) {
      const data = {
        newPassword: this.resetPassword.value.password,
        email: this.email
        // newPassword: this.resetPassword.value.password
      }
      this.commonService.showSpinner()
      this.commonService.postApi(ApiEndPoint.resetPassword, data, 0).subscribe((res: any) => {
        // console.log('reset password response ==>', res);
        if (res.responseCode == 200) {
          this.commonService.hideSpinner();
          this.commonService.successToast(res.responseMessage)
          this.router.navigateByUrl('/login')
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage)
        }
      })
    } else {
      // console.log('Email is required.');
      this.commonService.errorToast('Email is required.')
    }
  }

}

