import { Component, OnInit } from '@angular/core';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/provider/common.service';
import { Router } from '@angular/router';
import { RegEx, ValidationMessage } from 'src/app/config/validation-message';

@Component({
  selector: 'app-add-arm',
  templateUrl: './add-arm.component.html',
  styleUrls: ['./add-arm.component.scss']
})
export class AddArmComponent implements OnInit {
  addArmForm: FormGroup
  imageUrl
  validationMessage = ValidationMessage; //global validation code
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.addArmFormValidation();
  }
  addArmFormValidation() {
    this.addArmForm = new FormGroup({
      'arm': new FormControl('', [Validators.required, Validators.pattern(RegEx.name)]),
      'image': new FormControl('')
    })
  }

  /**
   * @call validation 
   */
  get colorName() { return this.addArmForm.get('colorName') }
  get arm() { return this.addArmForm.get('arm') }

  addArm() {
    console.log(this.colorArray);
    let data = {
      'armName': this.addArmForm.value.arm,
      'rawImage' : this.imageUrl
    }
    this.commonService.showSpinner();
    this.commonService.postApi(ApiEndPoint.armAdd, data, 1).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.responseCode == 200) {
        this.commonService.successToast(res.responseMessage);
        this.router.navigate(['/arm-management/list-arm'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  uploadImg(event): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi(ApiEndPoint.uploadFile, fb, 1).subscribe((res: any) => {
      console.log(res);
      if (res.responseCode == 200) {
        this.imageUrl = {webImage : res['result']['webImage'], mobileImage : res['result']['mobileImage'] }
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        // this.imageUrl = res['result']['mediaUrl'];
      }
    })
  }


  async uploadColorFunc(img, i) {
    var fb = new FormData();
    fb.append('file', img.target.files[0])
    this.commonService.showSpinner();
    let respose = await this.commonService.postFormDataApi(ApiEndPoint.uploadFile, fb, 1).toPromise()
    console.log(this.colorArray);
    this.commonService.hideSpinner();

    this.colorArray[i] = {
      'colorId': this.colorArray[i]['colorId'],
      'image': {webImage : respose['result']['webImage'], mobileImage : respose['result']['mobileImage'] }
    }

  }
  colorFieldArray: any = [1]
  colorArray: any = []
  addColour() {
    this.colorFieldArray.push(1)
  }
  showImage(event, i) {

    this.colorArray[i] = {
      'colorId': event.target.value,
      'image': ''
    }
    console.log(this.showImageField[i]);

    this.showImageField[i] = true;
    console.log(this.showImageField[i]);

  }


}
