import { Component, OnInit } from '@angular/core';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/provider/common.service';
import { Router } from '@angular/router';
import { ValidationMessage, RegEx } from 'src/app/config/validation-message';

@Component({
  selector: 'app-add-collection',
  templateUrl: './add-collection.component.html',
  styleUrls: ['./add-collection.component.scss']
})
export class AddCollectionComponent implements OnInit {
  addCategoryForm: FormGroup
  bodyArrayList: any = []
  embellishmentArrayList: any = []
  skirtArrayList: any = []
  skirtListArray: any = []
  armArrayList: any = []
  imageUrl: any
  validationMessage = ValidationMessage; //global validation code
  currentPage = 1
  itemPerPage = 10
  totalItems: any
  bodyListArray: any = []
  embellishmentListArray: any = []
  armListArray: any = []
  lengthArray: any = []

  collectionArray: any[] = [
    {
      costPrice: 'Cost Price (£)',
      body: 'Ellis empire dress',
      diamond: 'Diamond', embellishment: [
        { embellishmentId: 1, embellishmentName: 'Petala', embellishmentPrice: 2.5, },
        { embellishmentId: 2, embellishmentName: 'Polka', embellishmentPrice: 2.5, },
        { embellishmentId: 3, embellishmentName: 'Encrusted', embellishmentPrice: 2.5, }
      ],
      skirt: [
        { skirtId: 1, skirtName: 'GGT Skirt', skirtPrice: 35, longerLength: 0 },
        { skirtId: 1, skirtName: 'Tulle Skirt', skirtPrice: -2.75, longerLength: 1 },
        { skirtId: 1, skirtName: 'Full Skirt', skirtPrice: 5, longerLength: 1 },
      ],
      size: [
        { sizeName: 'Regular', sizePrice: 0 },
        { sizeName: 'Large', sizePrice: 0.15 },
        { sizeName: 'Extra Large', sizePrice: 0.25 }
      ],
      standardColorPrice: 0,
      premiumColorsPrice: 0
    }
  ];


  embellishmentWholeSale: any = [];
  skirtWholeSale: any = [];


  basePriceCostPrice: any = 0;
  bodyListCostPrice: any = [];

  basePriceWholeSale: any = 0;
  bodyListWholeSale: any = [];

  basePriceMRP: any = 0;
  bodyListMRP: any = [];


  // price header property
  embellishmentPriceHeader: any = [];
  skirtPriceHeader: any = [];
  diamondListArray: any = [];

  // embellishmentPriceHeader: any = [];

  maxLengthOfPriceFields: number = 10;

  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.addCategoryFormValidation();
    this.getBodyList()
    this.getSkirtList()
    this.getEmbellishmentList()
    this.getArmList();
    this.getDiamondList();
  }

  /**
   * @Form add category form validation
   */
  addCategoryFormValidation() {
    this.addCategoryForm = new FormGroup({
      'collectionName': new FormControl('', [Validators.required, Validators.pattern(RegEx.name)]),
      'collectionImage': new FormControl('', (Validators.required)),
      'body': new FormControl(''),
      'bodyPrice': new FormControl(''),
      'skirt': new FormControl(''),
      'skirtPrice': new FormControl(''),
      'embelishment': new FormControl(''),
      'embelishmentPrice': new FormControl(''),
      'arm': new FormControl(''),
      'armPrice': new FormControl(''),
      'regularSizePrice': new FormControl('', Validators.required),
      'largeSizePrice': new FormControl('', Validators.required),
      'xLargeSizePrice': new FormControl('', Validators.required),
    })
  }

  /**
   * @call validation 
   */
  get categoryName() { return this.addCategoryForm.get('categoryName') }


  /**
   * @Add category  use post api 
   */
  // addCategory() {
  //   console.log(this.addCategoryForm.value);
  // }


  /**
   * @function uploadImage is used to upload image
   */
  uploadImage(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi(ApiEndPoint.uploadFile, fb, 1).subscribe((res: any) => {
      console.log(res);
      if (res.responseCode == 200) {
        this.imageUrl = res['result'];
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
      }
    })
  }

  //add body field dynamic
  addBodySectionDynamic() {
    if (this.bodyArrayList.length && (this.bodyArrayList[this.bodyArrayList.length - 1].bodyId == '')) {
      return this.commonService.infoToast('Please select previous added body.')
    }
    this.bodyArrayList.push({
      bodyId: '',
      // addOnPrice: ''
    })
  }

  removeBodySectionDynamic(index: number) {
    this.bodyArrayList.splice(index, 1)

    // remove body row in matrix
    this.bodyListCostPrice.splice(index, 1)
    this.bodyListWholeSale.splice(index, 1)
    this.bodyListMRP.splice(index, 1)
    // end remove body row in matrix
  }

  //add embellishment field dynamic
  addEmbellishmentSectionDynamic() {
    if (this.embellishmentArrayList.length && (this.embellishmentArrayList[this.embellishmentArrayList.length - 1].embellishmentId == '')) {
      return this.commonService.infoToast('Please select previous added embellishment.')
    }
    this.embellishmentArrayList.push({
      embellishmentId: '',
      // addOnPrice: ''
    })
  }
  removeEmbellishmentSectionDynamic(index: Number) {
    this.embellishmentArrayList.splice(index, 1)

    // remove embllishment header and body price column
    this.embellishmentPriceHeader.splice(index, 1)
    // cost price
    this.bodyListCostPrice.forEach(element => {
      element.embellishment.splice(index, 1)
    });
    // wholesale price
    this.bodyListWholeSale.forEach(element => {
      element.embellishment.splice(index, 1)
    });
    // mrp
    this.bodyListMRP.forEach(element => {
      element.embellishment.splice(index, 1)
    });
    // end remove embllishment header and body price column

  }

  //add skirt field dynamic
  addSkirtSectionDynamic() {
    if (this.skirtArrayList.length && (this.skirtArrayList[this.skirtArrayList.length - 1].skirtId == '')) {
      return this.commonService.infoToast('Please select previous added skirt.')
    }
    this.skirtArrayList.push({
      skirtId: '',
      // addOnPrice: ''
    })
  }

  removeSkirtSectionDynamic(index: Number) {
    this.skirtArrayList.splice(index, 1)

    // remove skirt header and body price column
    this.skirtPriceHeader.splice(index, 1)
    // cost price
    this.bodyListCostPrice.forEach(element => {
      element.skirt.splice(index, 1)
    });
    // wholesale price
    this.bodyListWholeSale.forEach(element => {
      element.skirt.splice(index, 1)
    });
    // mrp
    this.bodyListMRP.forEach(element => {
      element.skirt.splice(index, 1)
    });
    // end remove skirt header and body price column
  }

  //add arm field dynamic
  addArmSectionDynamic() {
    if (this.armArrayList.length && (this.armArrayList[this.armArrayList.length - 1].armId == '')) {
      return this.commonService.infoToast('Please select previous added arm.')
    }
    this.armArrayList.push({
      armId: '',
      // addOnPrice: ''
    })
  }

  removeArmSectionDynamic(index: Number) {
    this.armArrayList.splice(index, 1)
  }


  addExtraLength(user?: any) {
    setTimeout(() => {
      this.lengthArray.push({
        type: '',
        addOnPrice: ''
        // embellishmentId: '',
        // image: {}
      })
    }, 100)
  }
  removeExtraLengthDynamic(index: number) {
    this.lengthArray.splice(index, 1)
  }


  // -------------------- get list body api --------------------- //
  getBodyList() {
    let apiReqUrl: any = `${ApiEndPoint.listBody}`
    this.commonService.showSpinner();
    let apiReqData: any = {
      page: this.currentPage,
      limit: this.itemPerPage,
    }
    // remove empty keys from request body
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.responseCode == 200) {
        this.bodyListArray = res.result.docs ? res.result.docs : '';
        this.totalItems = res.result.total
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      console.log(err);
      if (err.responseCode == 404) {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }

    })
  }

  //----- get skirt list api----//
  getSkirtList() {
    let apiReqUrl: any = `${ApiEndPoint.skirtListWithPagination}`
    let apiReqData: any = {
      page: this.currentPage,
      limit: this.itemPerPage,
    }
    this.commonService.showSpinner();
    // remove empty keys from request body
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.skirtListArray = res.result.docs ? res.result.docs : '';
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      } else {
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    },
      error => {
        this.skirtListArray = []
        this.totalItems = 0
      })
  }

  //---- get embellishment list api ----//
  getEmbellishmentList() {
    let apiReqUrl: any = `${ApiEndPoint.embellishmentList}`
    let apiReqData: any = {
      page: this.currentPage,
      limit: this.itemPerPage
    }
    // remove empty keys from request body
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.responseCode == 200) {
        this.embellishmentListArray = res.result.docs ? res.result.docs : '';
        this.totalItems = res.result.total
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      } else {
        this.embellishmentListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      console.log(err);
      if (err.responseCode == 404) {
        this.embellishmentListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }

    })
  }


  // -------------------- get category list --------------------- //
  getArmList() {
    let apiReqUrl: any = `${ApiEndPoint.armList}`
    this.commonService.showSpinner();
    let apiReqData: any = {
      page: this.currentPage,
      limit: this.itemPerPage,
    }
    // remove empty keys from request body
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.responseCode == 200) {
        this.armListArray = res.result.docs ? res.result.docs : '';
        this.totalItems = res.result.total
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      } else {
        this.armListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      console.log(err);

      if (err.responseCode == 404) {
        this.armListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }

    })
  }

  // -------------------- get category list --------------------- //
  getDiamondList() {
    let apiReqUrl: any = `${ApiEndPoint.listDiamond}`
    this.commonService.showSpinner();
    // remove empty keys from request body
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.responseCode == 200) {
        this.diamondListArray = res.result ? res.result : '';
        this.totalItems = res.result.total
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      } else {
        this.diamondListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      console.log(err);
      if (err.responseCode == 404) {
        this.diamondListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
    })
  }


  //------- add collection api ------//
  addCollection() {
    // validation
    if (!this.addCategoryForm.value.collectionName) {
      return this.commonService.infoToast('Collection name is required.')
    } else if (!this.bodyArrayList.length) {
      return this.commonService.infoToast('Atleast one body is required.')
    } else if (!this.skirtArrayList.length) {
      return this.commonService.infoToast('Atleast one skirt is required.')
    } else if (this.basePriceCostPrice == 0) {
      return this.commonService.infoToast('Cost price can not be 0.')
    } else if (this.basePriceWholeSale == 0) {
      return this.commonService.infoToast('Wholesale price can not be 0.')
    } else if (this.basePriceMRP == 0) {
      return this.commonService.infoToast('MRP price can not be 0.')
    }
    // end validation
    let apiReqUrl = "product/addCollection"
    let apiReqData = {
      "collectionName": this.addCategoryForm.value.collectionName,
      "bodyArray": this.bodyArrayList,
      "skirtArray": this.skirtArrayList,
      "armArray": this.armArrayList,
      "embellishmentArray": this.embellishmentArrayList,
      // "extraLength": this.lengthArray,
      // "size": [
      //   {
      //     "type": "REGULAR",
      //     "addOnPrice": this.addCategoryForm.value.regularSizePrice
      //   },
      //   {
      //     "type": "LARGE",
      //     "addOnPrice": this.addCategoryForm.value.largeSizePrice
      //   },
      //   {
      //     "type": "EXTRA_LARGE",
      //     "addOnPrice": this.addCategoryForm.value.xLargeSizePrice
      //   }
      // ],
      "image": this.imageUrl,
      "price": {
        "costPrice": {
          "basePrice": this.basePriceCostPrice,
          "bodyList": this.bodyListCostPrice,
        },
        "wholeSalePrice": {
          "basePrice": this.basePriceWholeSale,
          "bodyList": this.bodyListWholeSale
        },
        "mrp": {
          "basePrice": this.basePriceMRP,
          "bodyList": this.bodyListMRP
        }
      }
    }
    console.log('apireqData collection=====>', apiReqData)
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner()
        this.commonService.successToast(res.responseMessage)
        this.router.navigate(['/collection-management/list-collection'])
      } else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.commonService.hideSpinner()
      this.commonService.errorToast(err.responseMessage)
    })
  }



  /**
   * ===================== Matrix functionlaity start ======================== *
   * @param item 
   * @param selectedItemIndex 
   * @param additionType 
   */
  checkForDuplicate(item, selectedItemIndex, additionType) {
    console.log(item);
    console.log(additionType);

    switch (additionType) {
      case 'BODY':
        // console.log(parentIndex);
        let selectedBodyIndex = this.bodyArrayList.filter(x => x.bodyId == item.bodyId);
        if (selectedBodyIndex.length > 1) {
          // console.log("iffffffff");
          setTimeout(() => {
            this.bodyArrayList[selectedItemIndex].bodyId = ''
          }, 200);
          this.commonService.warningToast('Please select another Body, You have allready selected this.')
        } else {
          // this.addSkirtPriceHeader(item)
          this.addBodyPriceHeader(item, selectedItemIndex)
        }
        break;

      // skirt
      case 'SKIRT':
        // console.log(parentIndex);
        let selectedSkirtIndex = this.skirtArrayList.filter(x => x.skirtId == item.skirtId);
        if (selectedSkirtIndex.length > 1) {
          // console.log("iffffffff");
          setTimeout(() => {
            this.skirtArrayList[selectedItemIndex].skirtId = ''
          }, 200);
          this.commonService.warningToast('Please select another Skirt, You have allready selected this ')
        } else {
          // this.addSkirtPriceHeader(item)
          this.addSkirtPriceHeader(item)
        }
        break;

      // embellishment
      case 'EMBELLISHMENT':
        // console.log(parentIndex);
        let selectedEmbIndex = this.embellishmentArrayList.filter(x => x.embellishmentId == item.embellishmentId);
        if (selectedEmbIndex.length > 1) {
          // console.log("iffffffff");
          setTimeout(() => {
            this.embellishmentArrayList[selectedItemIndex].embellishmentId = ''
          }, 200);
          this.commonService.warningToast('Please select another Embellishment, You have allready selected this ')
        } else {
          this.addEmbellishmentPriceHeader(item)
        }
        break;

      // embellishment
      case 'ARM':
        // console.log(parentIndex);
        let selectedArmIndex = this.armArrayList.filter(x => x.armId == item.armId);
        if (selectedArmIndex.length > 1) {
          // console.log("iffffffff");
          setTimeout(() => {
            this.armArrayList[selectedItemIndex].armId = ''
          }, 200);
          this.commonService.warningToast('Please select another Arm, You have allready selected this ')
        }
        // else {
        //   this.addArmSectionDynamic()
        // }
        break;
    }
  }


  addBodyPriceHeader(item, selectedItemIndex) {
    console.log(item);
    // cost price body
    this.bodyListCostPrice[selectedItemIndex] = {
      "bodyId": item.bodyId,
      // "diamondId": '',
      "diamondId": null,
      "embellishment": [],
      "skirt": [],
      "size": [
        {
          "type": "REGULAR",
          "price": 0
        },
        {
          "type": "LARGE",
          "price": 0
        },
        {
          "type": "EXTRA_LARGE",
          "price": 0
        }
      ],
      "standardColor": 0,
      "premiumColor": 0
    }
    this.bodyListWholeSale[selectedItemIndex] = {
      "bodyId": item.bodyId,
      // "diamondId": '',
      "diamondId": null,
      "embellishment": [],
      "skirt": [],
      "size": [
        {
          "type": "REGULAR",
          "price": 0
        },
        {
          "type": "LARGE",
          "price": 0
        },
        {
          "type": "EXTRA_LARGE",
          "price": 0
        }
      ],
      "standardColor": 0,
      "premiumColor": 0
    }
    this.bodyListMRP[selectedItemIndex] = {
      "bodyId": item.bodyId,
      // "diamondId": '',
      "diamondId": null,
      "embellishment": [],
      "skirt": [],
      "size": [
        {
          "type": "REGULAR",
          "price": 0
        },
        {
          "type": "LARGE",
          "price": 0
        },
        {
          "type": "EXTRA_LARGE",
          "price": 0
        }
      ],
      "standardColor": 0,
      "premiumColor": 0
    }
    // embellishment
    this.embellishmentArrayList.forEach(element => {
      let findItem = this.embellishmentListArray.filter(x => { return x._id == element.embellishmentId })
      console.log(findItem);

      if (findItem.length) {
        // cost price
        this.bodyListCostPrice[selectedItemIndex].embellishment.push({
          "embellishmentId": findItem[0]._id,
          "price": 0
        })

        // whole sale price
        this.bodyListWholeSale[selectedItemIndex].embellishment.push({
          "embellishmentId": findItem[0]._id,
          "price": 0
        })

        // MRP
        this.bodyListMRP[selectedItemIndex].embellishment.push({
          "embellishmentId": findItem[0]._id,
          "price": 0
        })
      }
    });
    // end embellishment

    // skirt embellishment
    this.skirtArrayList.forEach(element => {
      let findItem = this.skirtListArray.filter(x => { return x._id == element.skirtId })
      console.log(findItem);
      if (findItem.length) {
        this.bodyListCostPrice[selectedItemIndex].skirt.push({
          "skirtId": findItem[0]._id,
          "price": 0,
          "extraLength": 0
        })

        this.bodyListWholeSale[selectedItemIndex].skirt.push({
          "skirtId": findItem[0]._id,
          "price": 0,
          "extraLength": 0
        })

        this.bodyListMRP[selectedItemIndex].skirt.push({
          "skirtId": findItem[0]._id,
          "price": 0,
          "extraLength": 0
        })
      }
    });
    // end skirt embellishment
  }



  addEmbellishmentPriceHeader(item) {
    // console.log(item);
    let findItem = this.embellishmentListArray.filter(x => { return x._id == item.embellishmentId })
    // console.log(findItem);
    this.embellishmentPriceHeader.push(findItem[0])
    this.addEmbellishmentPriceHeaderInPreviousIndex(item)
  }

  addEmbellishmentPriceHeaderInPreviousIndex(item) {

    // cost price
    this.bodyListCostPrice.forEach((element, idx) => {
      console.log(element);
      if (element.embellishment.length) {
        console.log(element.embellishment.length);
        let findIndexOfEmbellishmentId = element.embellishment.findIndex(x => x.embellishmentId == item.embellishmentId);
        console.log(findIndexOfEmbellishmentId);

        if (findIndexOfEmbellishmentId == -1) {
          let findItem = this.embellishmentListArray.filter(x => { return x._id == item.embellishmentId })
          console.log(findItem);
          this.bodyListCostPrice[idx].embellishment.push({
            "embellishmentId": findItem[0]._id,
            "price": 0
          })
        }
      } else {
        let findIndexOfEmbellishmentId = element.embellishment.findIndex(x => x.embellishmentId == item.embellishmentId);
        console.log(findIndexOfEmbellishmentId);

        if (findIndexOfEmbellishmentId == -1) {
          let findItem = this.embellishmentListArray.filter(x => { return x._id == item.embellishmentId })
          console.log(findItem);
          this.bodyListCostPrice[idx].embellishment.push({
            "embellishmentId": findItem[0]._id,
            "price": 0
          })
        }
      }
    });

    // whole sale price
    this.bodyListWholeSale.forEach((element, idx) => {
      console.log(element);
      if (element.embellishment.length) {
        console.log(element.embellishment.length);
        let findIndexOfEmbellishmentId = element.embellishment.findIndex(x => x.embellishmentId == item.embellishmentId);
        console.log(findIndexOfEmbellishmentId);

        if (findIndexOfEmbellishmentId == -1) {
          let findItem = this.embellishmentListArray.filter(x => { return x._id == item.embellishmentId })
          console.log(findItem);
          this.bodyListWholeSale[idx].embellishment.push({
            "embellishmentId": findItem[0]._id,
            "price": 0
          })
        }
      } else {
        let findIndexOfEmbellishmentId = element.embellishment.findIndex(x => x.embellishmentId == item.embellishmentId);
        console.log(findIndexOfEmbellishmentId);

        if (findIndexOfEmbellishmentId == -1) {
          let findItem = this.embellishmentListArray.filter(x => { return x._id == item.embellishmentId })
          console.log(findItem);
          this.bodyListWholeSale[idx].embellishment.push({
            "embellishmentId": findItem[0]._id,
            "price": 0
          })
        }
      }
    });

    // MRP 
    this.bodyListMRP.forEach((element, idx) => {
      console.log(element);
      if (element.embellishment.length) {
        console.log(element.embellishment.length);
        let findIndexOfEmbellishmentId = element.embellishment.findIndex(x => x.embellishmentId == item.embellishmentId);
        console.log(findIndexOfEmbellishmentId);

        if (findIndexOfEmbellishmentId == -1) {
          let findItem = this.embellishmentListArray.filter(x => { return x._id == item.embellishmentId })
          console.log(findItem);
          this.bodyListMRP[idx].embellishment.push({
            "embellishmentId": findItem[0]._id,
            "price": 0
          })
        }
      } else {
        let findIndexOfEmbellishmentId = element.embellishment.findIndex(x => x.embellishmentId == item.embellishmentId);
        console.log(findIndexOfEmbellishmentId);

        if (findIndexOfEmbellishmentId == -1) {
          let findItem = this.embellishmentListArray.filter(x => { return x._id == item.embellishmentId })
          console.log(findItem);
          this.bodyListMRP[idx].embellishment.push({
            "embellishmentId": findItem[0]._id,
            "price": 0
          })
        }
      }
    });
  }


  addSkirtPriceHeader(item) {
    // console.log(item);
    let findItem = this.skirtListArray.filter(x => { return x._id == item.skirtId })
    this.skirtPriceHeader.push(findItem[0])
    this.addSkirtPriceHeaderInPreviousIndex(item)
  }

  addSkirtPriceHeaderInPreviousIndex(item) {
    // cost price 
    this.bodyListCostPrice.forEach((element, idx) => {
      console.log(element);
      if (element.skirt.length) {
        console.log(element.skirt.length);
        let findIndexOfSkirtId = element.skirt.findIndex(x => x.skirtId == item.skirtId);
        console.log(findIndexOfSkirtId);

        if (findIndexOfSkirtId == -1) {
          let findItem = this.skirtListArray.filter(x => { return x._id == item.skirtId })
          console.log(findItem);
          this.bodyListCostPrice[idx].skirt.push({
            "skirtId": findItem[0]._id,
            "price": 0,
            "extraLength": 0
          })
        }
      } else {
        let findIndexOfSkirtId = element.skirt.findIndex(x => x.skirtId == item.skirtId);
        console.log(findIndexOfSkirtId);

        if (findIndexOfSkirtId == -1) {
          let findItem = this.skirtListArray.filter(x => { return x._id == item.skirtId })
          console.log(findItem);
          this.bodyListCostPrice[idx].skirt.push({
            "skirtId": findItem[0]._id,
            "price": 0,
            "extraLength": 0
          })
        }
      }
    });

    // whole sale price
    this.bodyListWholeSale.forEach((element, idx) => {
      console.log(element);
      if (element.skirt.length) {
        console.log(element.skirt.length);
        let findIndexOfSkirtId = element.skirt.findIndex(x => x.skirtId == item.skirtId);
        console.log(findIndexOfSkirtId);

        if (findIndexOfSkirtId == -1) {
          let findItem = this.skirtListArray.filter(x => { return x._id == item.skirtId })
          console.log(findItem);
          this.bodyListWholeSale[idx].skirt.push({
            "skirtId": findItem[0]._id,
            "price": 0,
            "extraLength": 0
          })
        }
      } else {
        let findIndexOfSkirtId = element.skirt.findIndex(x => x.skirtId == item.skirtId);
        console.log(findIndexOfSkirtId);

        if (findIndexOfSkirtId == -1) {
          let findItem = this.skirtListArray.filter(x => { return x._id == item.skirtId })
          console.log(findItem);
          this.bodyListWholeSale[idx].skirt.push({
            "skirtId": findItem[0]._id,
            "price": 0,
            "extraLength": 0
          })
        }
      }
    });

    // MRP
    this.bodyListMRP.forEach((element, idx) => {
      console.log(element);
      if (element.skirt.length) {
        console.log(element.skirt.length);
        let findIndexOfSkirtId = element.skirt.findIndex(x => x.skirtId == item.skirtId);
        console.log(findIndexOfSkirtId);

        if (findIndexOfSkirtId == -1) {
          let findItem = this.skirtListArray.filter(x => { return x._id == item.skirtId })
          console.log(findItem);
          this.bodyListMRP[idx].skirt.push({
            "skirtId": findItem[0]._id,
            "price": 0,
            "extraLength": 0
          })
        }
      } else {
        let findIndexOfSkirtId = element.skirt.findIndex(x => x.skirtId == item.skirtId);
        console.log(findIndexOfSkirtId);

        if (findIndexOfSkirtId == -1) {
          let findItem = this.skirtListArray.filter(x => { return x._id == item.skirtId })
          console.log(findItem);
          this.bodyListMRP[idx].skirt.push({
            "skirtId": findItem[0]._id,
            "price": 0,
            "extraLength": 0
          })
        }
      }
    });
  }

}
