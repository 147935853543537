import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-view-arm',
  templateUrl: './view-arm.component.html',
  styleUrls: ['./view-arm.component.scss']
})
export class ViewArmComponent implements OnInit {

  bodyId: any;
  armListArray: any;
  colorList : any = [];
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.bodyId = res.bodyId;
    })
    this.viewArm()
  }

  // get category detail by id
  viewArm() {
    let apiReqUrl: any = `${ApiEndPoint.viewArm + '?_id=' + this.bodyId}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.armListArray = res.result;
        this.colorList = res.result.colors
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
}

