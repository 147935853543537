<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">VAT Details</h1>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <!-- <div class="row justify-content-end mb-1 mr-2">
                        <button class="btn" style="color: #000;"
                            [routerLink]="['/tax-shipping-management/edit-Vat']">Update VAT</button>
                    </div> -->
                    <div class="tab-content1">
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="hospital list">
                                <thead>
                                    <tr class="no_wrap_th text-center">
                                        <th scope="col" class="Sorting-img">S.No.</th>
                                        <th scope="col" class="Sorting-img">Country</th>
                                        <th scope="col" class="action_td_btn3 Sorting-img">Vat Rates</th>
                                        <th scope="col" class="action_td_btn3 Sorting-img">Created At</th>
                                        <th scope="col" class="action_td_btn3 Sorting-img">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of vatDetails | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: totalItems}; let i = index"
                                        class="text-center">
                                        <td>{{itemPerPage * (currentPage-1)+ i+1}}</td>
                                        <td class="content" style="width: 100px;">
                                            {{item?.country || '--'}}</td>
                                        <td class="">
                                            <span *ngFor="let data of item.vatRates">
                                                {{data?.name}} &nbsp;
                                                {{data?.rates}}% ,
                                            </span>
                                        </td>
                                        <td class="content">
                                            {{(item?.createdAt | date:'medium') || '--'}}</td>
                                        <td>
                                            <div class="justify-content-center d-flex">
                                                <button class="btn btn-info ml-2 bt-margin" title="edit"
                                                    (click)="editTrunk(item?._id)"><i class="fa fa-edit"
                                                        aria-hidden="true" style="color:white"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!vatDetails?.length">
                                        <td class="table-no-record " colspan="5">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > 10">
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>