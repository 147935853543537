import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { ValidationMessage, RegEx } from 'src/app/config/validation-message';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-edit-diamond',
  templateUrl: './edit-diamond.component.html',
  styleUrls: ['./edit-diamond.component.scss']
})
export class EditDiamondComponent implements OnInit {

  editDiamondForm: FormGroup
  imageUrl
  validationMessage = ValidationMessage; //global validation code
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  categoryId: any;
  colorListArray: any = []
  showImageField: any = [];
  diamondId: any;
  constructor(private activatedroute: ActivatedRoute, private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.diamondId = res.diamondId;
    })
    this.editDiamondFormValidation();
    this.getColorList();
    this.viewDiamond()
  }
  editDiamondFormValidation() {
    this.editDiamondForm = new FormGroup({
      'diamond': new FormControl('', [Validators.required]),
      'image': new FormControl('')

    })
  }

  /**
   * @call validation 
   */
  get diamond() { return this.editDiamondForm.get('diamond') }
  get diamondImage() { return this.editDiamondForm.get('image') }


  editDiamond() {
    // this.router.navigate(['/swatches/list-swatches'])
    let apiReqUrl: any = `${ApiEndPoint.editDiamond + '?_id=' + this.diamondId}`

    let data = {
      'diamondName': this.editDiamondForm.value.diamond,
      "rawImage": this.imageUrl
    }
    this.commonService.showSpinner();
    this.commonService.putApi(apiReqUrl, data, 1).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.responseCode == 200) {
        this.commonService.successToast(res.responseMessage);
        this.router.navigate(['/diamond-management/list-diamond'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  //view swatches
  // get category detail by id
  viewDiamond() {
    let apiReqUrl: any = `${ApiEndPoint.viewDiamond + '?_id=' + this.diamondId}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.editDiamondForm.patchValue({
          'diamond': res?.result?.diamondName,
        })
        this.imageUrl = res?.result?.rawImage
        // let i = 0
        // this.colorArray = res.result.colors
        // for (let item of res.result.colors) {
        //   this.showImageFetch(item.colorId, item.image.webImage, item.image.mobileImage, i)
        //   i++
        // }

        // this.subCategoryDetails = res.result;
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  colorNameArr: any = []
  showImageFetch(name, image, mobImage, i) {

    this.colorArray[i] = {
      'colorId': name,
      'image': { webImage: image, mobileImage: mobImage }
    }
    console.log(this.showImageField[i]);
    this.colorNameArr[i] = name
    this.showImageField[i] = true;
    console.log(this.showImageField[i]);

  }

  // -------------------- get category list --------------------- //
  getColorList() {
    let apiReqUrl: any = ApiEndPoint.colorsList
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      // console.log("get category management list response ==>", res)
      if (res.responseCode == 200) {
        this.colorListArray = res.result ? res.result : '';
        this.totalItems = res.result.total
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      } else {
        this.colorListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  uploadImg(event): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi(ApiEndPoint.uploadFile, fb, 1).subscribe((res: any) => {
      console.log(res);
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.imageUrl = { webImage: res['result']['webImage'], mobileImage: res['result']['mobileImage'] }
      } else {
        this.commonService.hideSpinner();
      }
    })
  }


  async uploadColorFunc(img, i) {
    var fb = new FormData();
    fb.append('file', img.target.files[0])
    this.commonService.showSpinner();
    let respose = await this.commonService.postFormDataApi(ApiEndPoint.uploadFile, fb, 1).toPromise()
    console.log(this.colorArray);
    this.commonService.hideSpinner()
    this.colorArray[i] = {
      'colorId': this.colorArray[i]['colorId'],
      // 'image': respose['result']['webView']
      'image': { webImage: respose['result']['webImage'], mobileImage: respose['result']['mobileImage'] }

    }

  }
  colorFieldArray: any = [1]
  colorArray: any = []
  addColour() {
    this.colorFieldArray.push(1)
  }
  showImage(event, i) {

    this.colorArray[i] = {
      'colorId': event.target.value,
      'image': ''
    }
    console.log(this.showImageField[i]);

    this.showImageField[i] = true;
    console.log(this.showImageField[i]);

  }

}
