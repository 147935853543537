import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  // baseURL = 'http://182.72.203.245:1888/api/v1/';
  // baseURL = 'https://node-flutter.mobiloitte.com/api/v1/';
  baseURL = environment.baseUrl


  public loginStatus = new Subject();
  public loginData = new Subject()

  constructor(private router: Router, public httpClient: HttpClient, private toastrService: ToastrService, private spinnerService: NgxSpinnerService) { }

  // ---------------- get Api function -------------------- //
  getApi(endPointURL, isHeader): Observable<any> {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': localStorage.getItem('mmAdminToken')
        })
      }
    }
    return this.httpClient.get(this.baseURL + endPointURL, httpHeaders)
  }
  // ---------------- get Api function -------------------- //
  getApi2(endPointURL, isHeader,paramsObj?: any): Observable<any> {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': localStorage.getItem('mmAdminToken')
        })
      }
    }
    let httpParams = new HttpParams();
    if (paramsObj) {
      Object.keys(paramsObj).forEach(key => {
        paramsObj[key] && (httpParams = httpParams.set(key, paramsObj[key]));
      });
    }
    
    const options = {
      ...httpHeaders,
      ...(paramsObj && { params: httpParams })
    };
    console.log(httpHeaders);
    
    return this.httpClient.get(this.baseURL + endPointURL, options)
  }


  // ---------------- post Api Function ------------------- //
  postApi(endPointURL, data, isHeader): Observable<any> {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': localStorage.getItem('mmAdminToken')
        })
      }
    }
    return this.httpClient.post(this.baseURL + endPointURL, data, httpHeaders)
  }


  // ------------------ put Api Function ----------------- //
  putApi(endPointURL, data, isHeader): Observable<any> {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': localStorage.getItem('mmAdminToken')
        })
      }
    }
    return this.httpClient.put(this.baseURL + endPointURL, data, httpHeaders)
  }


  // ------------------ delete Api Function -------------- //
  deleteApi(endPointURL, bodyData, isHeader): Observable<any> {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': localStorage.getItem('mmAdminToken')
        }),
        body: bodyData
      }
    }
    return this.httpClient.delete(this.baseURL + endPointURL, httpHeaders)
  }

  //  =-=-=-=-=-==- upload file =-=-==-=-=-=-==-=-

  postFormDataApi(endPoint, data, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          // "Content-Type": "application/json",
        }),
      };
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          // "Content-Type": "application/json",
          token: localStorage.getItem("mmAdminToken"),
        }),
      };
    }
    return this.httpClient.post(this.baseURL + endPoint, data, httpHeaders);
  }

  // check admin login or not
  public isLoggedIn() {
    return localStorage.getItem('mmAdminToken') !== null;
  }


  // logout
  public logout() {
    localStorage.removeItem('mmAdminToken')
    this.loginData.next('');
    this.loginStatus.next(false)
    this.router.navigate(['login'])
  }

  // spinner service
  showSpinner() {
    this.spinnerService.show();
  }
  hideSpinner() {
    this.spinnerService.hide();
  }

  // toastr service
  successToast(msg) {
    this.toastrService.success(msg);
  }
  errorToast(msg) {
    this.toastrService.error(msg);
  }
  warningToast(msg) {
    this.toastrService.warning(msg);
  }
  infoToast(msg) {
    this.toastrService.info(msg);
  }

  // remove empty key from object
  removeEmptyKey(obj) {
    Object.entries(obj).forEach(([key, val]) => val === '' && delete obj[key]);
    return obj
  }


  /** to prevent first space */
  preventSpace(event) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === 'Backspace')
      k = 8;
    if (k >= 48 && k <= 57 || k == 8 || k == 46)
      return true;
    else
      return false;
  }

  // Split string on the first white space 
  getStringBeforeFirstWhiteSpace(str) {
    if (str) {
      let strFirstWord = str.split(' ')[0];
      console.log(strFirstWord);
      return strFirstWord
    }
  }

  addDaysToDate(date, noOfDay) {

  }
  month: any;
  day: any;
  daily: string;
  year: number;
  dtToday: Date;
  maxDate: string;
  BlockFuture() {
    $(() => {
      this.dtToday = new Date();
      this.month = this.dtToday.getMonth() + 1;
      this.day = this.dtToday.getDate();
      this.year = this.dtToday.getFullYear();
      if (this.month < 10)
        this.month = '0' + this.month.toString();
      if (this.day < 10)
        this.day = '0' + this.day.toString();
      this.maxDate = this.year + '-' + this.month + '-' + this.day;
      $('#fromDate').attr('max', this.maxDate);
      $('#toDate').attr('max', this.maxDate);
    });
  }

}