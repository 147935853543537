import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showLoginHeader: boolean;
  showLogo: boolean = false;
  selected: any;

  constructor(public commonService: CommonService) { }

  ngOnInit() {
    this.commonService.loginStatus.subscribe((res: boolean) => console.log("status", this.showLoginHeader = res))
    if (localStorage.getItem('mmAdminToken')) {
      this.showLoginHeader = true
    }
  }

  head() {
    if (this.showLogo == false) {
      if ((document.getElementById("logo").style.width = "45px")) {
        document.getElementById("logo").style.display = "none";
        this.showLogo = true;
      }
    }
    else {
      document.getElementById("logo1").style.display = "none";
      this.showLogo = false;
    }
  }

}