import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';


declare var $: any
@Component({
  selector: 'app-list-arm',
  templateUrl: './list-arm.component.html',
  styleUrls: ['./list-arm.component.scss']
})
export class ListArmComponent implements OnInit {

  searchForm: FormGroup;
  armListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;

  categoryId: any;

  // hospitalIds: any = [];
  // // checked: any
  // isCheckedAll: any = false;
  // checked: any
  // toDate: string | number | Date;
  // newDate: string | number | Date;
  // oldDate: string | number | Date;
  // fromDate: string | number | Date;
  // today: string
  today = new Date().toISOString().split('T')[0]
  userStatus: any = 'ACTIVE';
 
//    tooltips = require('tooltip')
 
// tooltip()

  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.searchFormValidation();
    // this.searchForm.get('fromDate').valueChanges.subscribe((x)=>{
    //   console.log('x',x);
    //   this.fromDate = x
    // })
    this.getEmbellishmentList();
    // this.commonService.BlockFuture()
    // this.today = new Date().toISOString()
    console.log(this.today)
  }

  // Search by Date
  //    searchByDate(){

  //  }

  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      //   if(this.searchForm.value.fromDate > this.searchForm.value.toDate){
      //     this.commonService.errorToast('To date should be greater than From date')
      //     // return
      //     // this.searchForm.value.fromDate = '';
      //     // this.messege = "please enter right date"
      //   }
      //   else if(this.searchForm.value.fromDate <= this.searchForm.value.toDate){

      //  this.oldDate = new Date(this.searchForm.value.fromDate).toISOString()
      //  this.newDate = new Date(this.searchForm.value.toDate+'T23:59:59.999Z')
      //  console.log('this.oldDate',this.oldDate );
      //  console.log('this.newDate',this.newDate );
      //       }
      // this.fromDate =this.searchForm.value.fromDate
      this.getEmbellishmentList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        status: '',
        fromDate: '',
        toDate: ''
      });
      this.getEmbellishmentList()
    }
  }

  selectStatus() {
    this.currentPage = 1
  }
  // -------------------- get category list --------------------- //
  getEmbellishmentList() {
    let apiReqUrl: any = `${ApiEndPoint.armList}`

    this.commonService.showSpinner();
    let apiReqData: any = {
      page: this.currentPage,
      limit: this.itemPerPage,
      search: this.searchForm.value.search,
      status1: this.searchForm.value.status,
      fromDate: this.searchForm.value.fromDate ? new Date(this.searchForm.value.fromDate).toISOString() : '',
      toDate: this.searchForm.value.toDate ? new Date(this.searchForm.value.toDate).toISOString() : ''
    }
    // remove empty keys from request body
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.postApi(apiReqUrl,apiReqData, 1).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.responseCode == 200) {
        this.armListArray = res.result.docs ? res.result.docs : '';
        this.totalItems = res.result.total
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      } else {
        this.armListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    },(err)=>{
      console.log(err);
      
      if(err.responseCode == 404){
        this.armListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
      
    })
  }

  pagination(event) {
    console.log(event)
    this.currentPage = event;
    this.getEmbellishmentList()
  }

  addBody() {
    this.router.navigate(['/arm-management/add-arm'])
  }
  // view category
  viewBody(bodyId) {
    // alert(bodyId)
    this.router.navigate(['/arm-management/view-arm'], { queryParams: { bodyId: bodyId } })
  }
 
  // edit category
  editBody(categoryId) {
    this.router.navigate(['/arm-management/edit-arm'], { queryParams: { categoryId: categoryId } })
  }
  // editCategory(categoryId) {
  //   this.router.navigate(['/embellishment/edit-embellishment'])
  // }
  // blockUnblockUserModal(){

  // }
  // ------------------------------- delete hospital ----------------------------- //
  deleteCategoryModal(categoryId) {
    $('#deleteCategory').modal('show')
    this.categoryId = categoryId
  }
  deleteCategory() {
    // let data = {
    //   hospitalId: this.hospitalId
    // }
    // console.log(data)
    let apiReqUrl: any = `${ApiEndPoint.embellishmentDelete + '?_id=' + this.categoryId}`
    this.commonService.showSpinner();
    this.commonService.deleteApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      // console.log("delete category user response ==>", res)
      $('#deleteCategory').modal('hide');
      if (res.responseCode == 200) {
        this.getEmbellishmentList()
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
}

