import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
declare var $: any;

@Component({
  selector: 'app-list-diamond',
  templateUrl: './list-diamond.component.html',
  styleUrls: ['./list-diamond.component.scss']
})
export class ListDiamondComponent implements OnInit {

  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  categoryId: any;
  today = new Date().toISOString().split('T')[0]
  userStatus: any = 'ACTIVE';
  diamondId: any;

  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.searchFormValidation();
    this.getDiamondList();
    console.log(this.today)
  }

 

  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
    
      this.getDiamondList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        status: '',
        fromDate: '',
        toDate: ''
      });
      this.getDiamondList()
    }
  }

  selectStatus() {
    this.currentPage = 1
  }
  // -------------------- get category list --------------------- //
  getDiamondList() {
    let apiReqUrl: any = `${ApiEndPoint.listDiamondWithPaginate}`
    this.commonService.showSpinner();
    let apiReqData: any = {
      page: this.currentPage,
      limit: this.itemPerPage,
      search: this.searchForm.value.search,
      status1: this.searchForm.value.status,
      fromDate: this.searchForm.value.fromDate ? new Date(this.searchForm.value.fromDate).toISOString() : '',
      toDate: this.searchForm.value.toDate ? new Date(this.searchForm.value.toDate).toISOString() : ''
    }
    // remove empty keys from request body
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.responseCode == 200) {
        this.bodyListArray = res.result.docs ? res.result.docs : '';
        this.totalItems = res.result.total
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      console.log(err);

      if (err.responseCode == 404) {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }

    })
  }

  pagination(event) {
    console.log(event)
    this.currentPage = event;
    this.getDiamondList()
  }

  addDiamond() {
    this.router.navigate(['/diamond-management/add-diamond'])
  }
  // view category
  viewDiamond(diamondId) {
    this.router.navigate(['/diamond-management/view-diamond'], { queryParams: { diamondId: diamondId } })
  }

  // edit category
  editDiamond(diamondId) {
    this.router.navigate(['/diamond-management/edit-diamond'], { queryParams: { diamondId: diamondId } })
  }

  // ------------------------------- delete hospital ----------------------------- //
  deleteColorConfirmationModal(diamondId){
    $('#deleteColor').modal('show')
    this.diamondId = diamondId
  }
 
  deleteColor() {
    // let data = {
    //   hospitalId: this.hospitalId
    // }
    // console.log(data)
    let apiReqUrl: any = `${ApiEndPoint.deleteDiamond + '?_id=' + this.diamondId}`
    this.commonService.showSpinner();
    this.commonService.deleteApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      console.log("delete category user response ==>", res)
      if (res.responseCode == 200) {
        $('#deleteColor').modal('hide');
        this.getDiamondList()
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
}
