<div class="login-wrapper">
    <div class="container-common">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="d-flex align-items-center minheight70vh">
                    <form class="login_box_outer w-100" [formGroup]="forgotPasswordForm">
                        <div class="login-box max-WT-520">
                            <div class="login-right-block">
                                <div class="login-heading">
                                    <h4>FORGOT PASSWORD</h4>
                                </div>
                                <div class="login-box-body">
                                    <p class="common-paragrph text-center">Enter your registered email id. We will
                                        help you to reset your password.</p>
                                    <div class="form-group">
                                        <input type="email" class="form-control" name="email" formControlName="email"
                                            placeholder="{{validationMessage.email.placeholder}}" maxlength="256"
                                            (keypress)="commonService.preventSpace($event)" />
                                        <div *ngIf="f.email.errors && (f.email.touched || f.email.dirty)"
                                            class="text-danger">
                                            <small
                                                *ngIf="f.email.hasError('required') ">{{validationMessage.email.required}}</small>
                                            <small *ngIf="f.email.hasError('pattern')">
                                                {{validationMessage.email.valid}}</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mt40 admin-profile">
                                    <button type="button" class="btn btn-login btn-large  width100 font-100"
                                        [disabled]="!forgotPasswordForm.valid" (click)="submit()">SUBMIT</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- otp modal -->
<div class="modal fade global-modal reset-modal" id="exampleModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">OTP</h5>
                </div>
                <div class="modal-inner-content">
                    <p class="common-paragrph text-center">Please enter OTP, We have sent an OTP on your email id.</p>
                    <div class="modal-body modal-body-custom">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <form [formGroup]="otpForm">
                                    <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:6}">
                                    </ng-otp-input>
                                    <div class="d-flex justify-content-between resend-otp">

                                        <a (click)="resendOtp()" class="resendOtp anchor-custom">Resend OTP</a>
                                        <p class="mt-10">{{time == '1.59' || time == '0.59' ? '' : time }}</p>
                                    </div>
                                    <div class="text-center mt40">
                                        <button mat-button class="btn btn-login btn-large  width100"
                                            [disabled]="!UserOtp" (click)="navigateToResetPassword()">CONFIRM
                                            OTP</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>


<!-- <div class="modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Please Enter OTP</h5>
                
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
</div> -->