import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-view-diamond',
  templateUrl: './view-diamond.component.html',
  styleUrls: ['./view-diamond.component.scss']
})
export class ViewDiamondComponent implements OnInit {

  diamondId: any;
  diamondArray: any;
  colorList: any = [];
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.diamondId = res.diamondId;
    })
    this.viewDiamond()
  }

  // get category detail by id
  viewDiamond() {
    let apiReqUrl: any = `${ApiEndPoint.viewDiamond + '?_id=' + this.diamondId}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.diamondArray = res.result;
        this.colorList = res.result.colors
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
}

