<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Diamond / Add</h1>
        </div>
        <div class="content-section">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="  mb40">
                    <div class="add-store-block input-style mb20 mt20 view-label-align">
                        <form [formGroup]="addDiamondForm" #f="ngForm">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">Diamond Name <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control"
                                            placeholder="{{validationMessage?.diamond.placeholder}}"
                                            formControlName="diamond" (keypress)="commonService.preventSpace($event)"
                                            maxlength="60">
                                        <div *ngIf="(diamond.invalid ) && diamond.touched || diamond.dirty">
                                            <small *ngIf="diamond.errors?.required"
                                                class="text-danger">{{validationMessage?.diamond.required}} </small>
                                       
                                            <small *ngIf="diamond.errors?.minlength"
                                                class="text-danger">{{validationMessage?.diamond.minLength}}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">Diamond Image <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group ">
                                        <input type="file" formControlName="image" name="image"
                                            accept="image/jpg,image/jpeg,image/png" id="image"
                                            (change)="uploadImg($event)">
                                        <small *ngIf="diamondImage.errors?.required && (diamondImage.touched || diamondImage.dirty)"
                                            class="text-danger">{{validationMessage?.diamond.diamondImages}} </small>
                                        <img *ngIf="imageUrl" [src]="imageUrl?.webImage" class="mt-1" alt="">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme"
                            routerLink="/diamond-management/list-diamond">Back</button>
                        <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="!addDiamondForm.valid"
                            (click)="addDiamond()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>