import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { ValidationMessage, RegEx } from 'src/app/config/validation-message';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-add-diamond',
  templateUrl: './add-diamond.component.html',
  styleUrls: ['./add-diamond.component.scss']
})
export class AddDiamondComponent implements OnInit {


  addDiamondForm: FormGroup
  imageUrl
  validationMessage = ValidationMessage; //global validation code
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.addDiamondFormValidation();
    // this.getColorList()
  }
  addDiamondFormValidation() {
    this.addDiamondForm = new FormGroup({
      'diamond': new FormControl('', [Validators.required]),
      'image': new FormControl('',[Validators.required])
    })
  }

  /**
   * @call validation 
   */
  get diamond() { return this.addDiamondForm.get('diamond') }
  get diamondImage() { return this.addDiamondForm.get('image') }



  addDiamond() {
    let data = {
      "diamondName": this.addDiamondForm.value.diamond,
      "rawImage": this.imageUrl
    }
    this.commonService.showSpinner();
    this.commonService.postApi(ApiEndPoint.createDiamond, data, 1).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.responseCode == 200) {
        this.commonService.successToast(res.responseMessage);
        this.router.navigate(['/diamond-management/list-diamond'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  // -------------------- get category list --------------------- //
  getColorList() {
    let apiReqUrl: any = ApiEndPoint.colorsList
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      // console.log("get category management list response ==>", res)
      if (res.responseCode == 200) {
        this.colorListArray = res.result ? res.result : '';
        this.totalItems = res.result.total
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      } else {
        this.colorListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  uploadImg(event): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi(ApiEndPoint.uploadFile, fb, 1).subscribe((res: any) => {
      console.log(res);
      if (res.responseCode == 200) {
        this.imageUrl = { webImage: res['result']['webImage'], mobileImage: res['result']['mobileImage'] }
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
      }
    })
  }


  async uploadColorFunc(img, i) {
    var fb = new FormData();
    fb.append('file', img.target.files[0])
    this.commonService.showSpinner();
    let respose = await this.commonService.postFormDataApi(ApiEndPoint.uploadFile, fb, 1).toPromise()
    console.log(this.colorArray);
    this.commonService.hideSpinner();

    this.colorArray[i] = {
      'colorId': this.colorArray[i]['colorId'],
      'image': { webImage: respose['result']['webImage'], mobileImage: respose['result']['mobileImage'] }
    }

  }
  colorFieldArray: any = [1]
  colorArray: any = []
  addColour() {
    this.colorFieldArray.push(1)
  }
  showImage(event, i) {

    this.colorArray[i] = {
      'colorId': event.target.value,
      'image': ''
    }
    console.log(this.showImageField[i]);

    this.showImageField[i] = true;
    console.log(this.showImageField[i]);

  }


}
