import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListCollectionComponent } from './list-collection/list-collection.component';
import { AddCollectionComponent } from './add-collection/add-collection.component';
import { EditCollectionComponent } from './edit-collection/edit-collection.component';
import { ViewCollectionComponent } from './view-collection/view-collection.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
const routes: Routes = [
  { path: '', redirectTo: 'list-collection', pathMatch: 'full' },
  { path: 'list-collection', component: ListCollectionComponent },
  { path: 'add-collection', component: AddCollectionComponent },
  { path: 'view-collection', component: ViewCollectionComponent },
  { path: 'edit-collection', component: EditCollectionComponent }
]


@NgModule({
  declarations: [ListCollectionComponent, AddCollectionComponent, EditCollectionComponent, ViewCollectionComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule

  ]
})
export class CollectionManagementModule { }
