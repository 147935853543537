export const ValidationMessage = {
    email: {
        placeholder: 'Enter Email Id',
        required: '*Email id is required.',
        valid: '*Please enter valid email.'
    },
    password: {
        placeholder: 'Password',
        confirmPlaceholder: 'Enter Confirm Password',
        required: '*Password is required.',
        valid: 'Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.',

        old: '*Please enter old password.',
        new: '*Please enter new password.',
        confirm: '*Please enter confirm password.',
        matched: 'Old password should not be same as password.',
        misMatch: 'Confirm password should be same as password.',
        matchedNew: 'Old password should not be same as new password.',
        misMatchNew: 'Confirm password should be same as new password.',
    },
    newPasswordPlaceholder: {
        placeholder: 'New Password',

    },
    currentPlaceholder: {
        placeholder: 'Current Password',
    },

    cnfrmPlaceholder: {
        placeholder: 'Confirm New Password',
    },
    name: {
        placeholder: 'Name',
        required: '*Name is required.',
        valid: '*Please enter valid name.',
        minLength: 'Name must be at least 2 characters long.',
        maxLength: 'Name maximum 60 characters allowed.',
    },
    firstName: {
        placeholder: 'Enter First Name',
        required: '*First name is required.',
        valid: '*Please enter valid first name.',
        minLength: 'First name must be at least 2 characters long.',
        maxLength: 'First name maximum 60 characters allowed.',
    },
    lastName: {
        placeholder: 'Enter Last Name',
        required: '*Last name is required.',
        valid: '*Please enter valid last name.',
        minLength: 'Last name must be at least 2 characters long.',
        maxLength: 'Last name maximum 60 characters allowed.',
    },
    phone: {
        placeholder: 'Phone Number',
        required: '*Phone number is required.',
        valid: '*Please enter valid phone number.',
        minLength: 'Phone number must be at least 7 digit long.',
        maxLength: 'Phone number maximum 15 digit allowed.'
    },
    countryCode: {
        required: '*Please select country code'
    },
    mobile: {
        placeholder: 'Mobile Number',
        required: '*Mobile number is required.',
        valid: '*Please enter valid mobile number.',
        minLength: 'Mobile number must be at least 7 digit long.',
        maxLength: 'Mobile number maximum 15 digit allowed.'
    },
    address: {
        placeholder: 'Address',
        required: '*Address is required.'
    },
    role: {
        required: '*Please select Role.'
    },
    roleName: {
        placeholder: 'Role name',
        required: '*Role name is required.',
        valid: '*Please enter valid role name.',
        minLength: 'Role name must be at least 2 characters long.',
    },
    country: {
        required: '*Please select country.'
    },
    vStatus: {
        required: '*Please select status.'
    },
    vatNum: {
        required: '*Please enter vat number.'
    },
    currency: {
        required: '*Please select currency'
    },
    currencyCode: {
        required: '*Please select currency code'
    },
    stateCode: {
        required: '*Please select state code'
    },
    priceType: { required: '*Please select price type' },
    event: {
        title: {
            placeholder: 'Event Title',
            required: '*Title is required.',
            valid: '*Please enter valid Title.',
            minLength: 'Title must be at least 2 characters long.',
            maxLength: 'Title maximum 60 characters allowed.',
        },
        type: {
            placeholder: 'Event Type',
            required: '*Event Type is required.',
        },
        description: {
            placeholder: 'Event Description',
            required: '*Event description is required.',
            minLength: 'Description must be at least 2 characters long.',

        },
        invite: {
            placeholder: 'Invite',
            required: '*Invite is required.',
        },
        maxPersonCapacity: {
            placeholder: 'Max Person Capacity',
            required: '*Max Person Capacity is required.',
        },
        pricePerPerson: {
            placeholder: 'Price Per Person',
            required: '*Price Per Person is required.',
        },
        seeEvent: {
            placeholder: 'See Event',
            required: '*See Event is required.',
        }
    },
    date: {
        placeholder: ' Date',
        required: '*Date is required.',
    },
    time: {
        placeholder: 'Time ',
        required: '*Time is required.',
    },
    chatMaxUserInGroup: {
        required: '*Chat Max User In Group is required.',

    },
    search: {
        placeholder: 'Search'
    },
    category: {
        placeholder: 'Enter Category Name',
        required: '*Please enter category name.',
        valid: '*Category name accept only alphabets.',
        minLength: '*Minimum length must be 2.',
        maxLength: '*Maximum length should not more than 60.'

    },
    selectCategory: {
        required: ' *Please select category name.'
    },
    selectSubCategory: {
        required: ' *Please select sub category name.'
    },
    selecetImg: {
        required: 'Please select images.'
    },
    subCategory: {
        placeholder: 'Enter Sub Category Name',
        required: '*Please enter sub category name.',
        valid: ' *SubCategory name accept only alphabets.',
        minLength: '*Minimum length must be 2.',
        maxLength: '*Maximum length should not more than 60.'

    },
    swatches: {
        placeholder: 'Enter Swatches Name',
        required: '*Please enter swatches name.',
        valid: '*Swatches accept only alphabets.',
        minLength: '*Minimum length must be 2.',
        maxLength: '*Maximum length should not more than 60.'
    },
    selectSwatches: {
        required: '*Please select swatches name.',
    },
    selectColor: {
        required: '*Please select colour.'
    },

    quantity: {
        placeholder: 'Enter Quantity',
        required: ' *Please enter quantity.'
    },
    faq: {
        placeholder: 'Enter Question',
        required: '*Please enter question.',
        minLength: '*Minimum length must be 2.',
        maxLength: '*Maximum length should not more than 60.'
    },
    faqAns: {
        placeholder: 'Enter Answer',
        required: '*Please enter answer.',
        minLength: '*Minimum length must be 2.',
        maxLength: '*Maximum length should not more than 60.'
    },
    faqTitle: {
        placeholder: 'Enter Title',
        required: '*Please enter title.',
        minLength: '*Minimum length must be 2.',
        maxLength: '*Maximum length should not more than 60.'
    },
    color: {
        placeholder: 'Enter Color Name',
        required: '*Please enter color name.',
        valid: '*Color name accept only alphabets.',
        minLength: '*Minimum length must be 2.',
        maxLength: '*Maximum length should not more than 60.'
    },
    colorCode: {
        placeholder: 'Enter Color Code',
        required: ' *Please enter color code name.',
        minLength: '*Minimum length must be 3.',
        maxLength: '*Maximum length should not more than 60.'

    },
    price: {
        placeholder: "Enter Price",
        required: '*Please enter price.',
        minLength: '*Minimum length must be 2.',
        maxLength: '*Maximum length should not more than 60.'
    },
    embellishment: {
        placeholder: 'Enter Embellishment Type',
        required: '*Please enter embellishment type.',
        valid: '*EmbellishmentType accept only alphabets.',
        minLength: '*Minimum length must be 2.',
        maxLength: '*Maximum length should not more than 60.'
    },
    rejectInfluencer: {
        placeholder: "Enter Reason",
        required: '*Please enter reason.',
        minLength: '*Minimum length must be 10.',
        maxLength: '*Maximum length should not more than 256.'
    },
    selectInfluencer: {
        required: '*Please select influencer.'
    },
    coupon: {
        placeholder: 'Enter Coupon Code',
        required: '*Please enter coupon code.',
        couponStartDate: '*Please enter coupon start date.',
        couponExpireDate: '*Please enter coupon expiry date.',
        discountPlaceholder: 'Enter Discount',
        discountRequired: '*Please enter discount.',
        couponName: 'Enter Coupon Name',
        couponNameRequired: '*Please enter coupon nane.',
        discountTypeRequired: '*Please select discount type.'
    },
    conversion: {
        placeholder: 'Enter Conversion Rate',
        required: '*Please enter conversion rate.'
    },

    silhouette: {
        placeholder: 'Enter Silhouette Name',
        required: '*Please enter silhouette.',
        minLength: '*Minimum length must be 2.',
        maxLength: '*Maximum length should not more than 60.'
    },
    neckline: {
        placeholder: 'Enter Neckline Name',
        required: '*Please enter neckline.',
        minLength: '*Minimum length must be 2.',
        maxLength: '*Maximum length should not more than 60.'
    },

    trade: {
        placeholder: 'Enter Trade Name',
        required: '*Please enter trade name.',
        minLength: '*Minimum length must be 2.',
        maxLength: '*Maximum length should not more than 60.'
    },

    trunk: {
        placeholder: 'Enter Trunk Name',
        required: '*Please enter trunk name.',
        minLength: '*Minimum length must be 2.',
        maxLength: '*Maximum length should not more than 60.'
    },
    location: {
        placeholder: 'Enter Location',
        required: '*Please enter location.',
    },
    description: {
        placeholder: 'Enter Description',
        required: '*Please enter description.',
        minLength: '*Minimum length must be 2.',
    },
    blog: {
        placeholder: 'Enter Title',
        required: '*Please enter title.',
        valid: '*Blog accept only alphabets.',
        minLength: '*Minimum length must be 2.',
        descriptionPlaceholder: 'Enter Description',
    },
    credit: {
        placeholder: 'Enter Credit',
        required: '*Please enter credit.',
        minLength: '*Minimum length must be 2.',
    },
    skirt: {
        placeholder: 'Enter Skirt',
        required: '*Please enter skirt name.',
        valid: '*Skirt accept only alphabets.',
        minLength: '*Minimum length must be 2.',
        descriptionPlaceholder: 'Enter Description',
        descriptionRequired: '*Please enter skirt description.'
    },
    body: {
        placeholder: 'Enter Body',
        required: '*Please enter body name.',
        valid: '*body accept only alphabets.',
        minLength: '*Minimum length must be 2.',
        descriptionPlaceholder: 'Enter Description',
        descriptionRequired: '*Please enter body description.'
    },
    diamond: {
        placeholder: 'Enter Diamond',
        required: '*Please enter diamond name.',
        valid: '*Diamond accept only alphabets.',
        minLength: '*Minimum length must be 2.',
        descriptionPlaceholder: 'Enter Description',
        descriptionRequired: '*Please enter diamond description.',
        diamondImages: '*Please upload diamond image.'
    },
    arm: {
        placeholder: 'Enter Arm',
        required: '*Please enter arm name.',
        valid: '*arm accept only alphabets.',
        minLength: '*Minimum length must be 2.',
        descriptionPlaceholder: 'Enter Description',
        descriptionRequired: '*Please enter arm description.'
    },
    logo: {
        placeholder: 'Enter Logo Name'
    },
    link: {
        placeholder: 'Enter Link Name',
        placeholder1: 'Enter Title Name',
        required: '*Please enter title name.',
        required1: '*Please enter link name.'
    },
    mask: {
        placeholder: 'Enter Mask Name',
        required: '*Please enter mask name.',
    },
    tax: {
        placeholder: 'Enter Tax Price',
        required: '*Please enter tax name.',
    },
    shippingCharge: {
        placeholder: 'Enter Shipping Price',
        required: '*Please enter shipping price.',
    },

}


// success message 
export const SuccessMessage = {
    loginSuccess: 'Login Successfully.'
}

// error message
export const ErrorMessage = {
    error_401: 'Unauthorized',
    error_404: 'Http failure response',
    somethingWentWrong: 'Something Went Wrong.'
}

// regular expression 
export const RegEx = {
    name: /^[A-Za-z][A-Za-z ]*$/,
    email: '[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,5}',
    paswdRegEx: '(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}',
    phoneNo: /^[1-9][0-9]{7,13}$/
}