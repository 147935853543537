export const ApiEndPoint = {
  login: 'admin/adminlogin',
  forgotPassword: 'admin/forgotPassword',
  resetPassword: 'admin/resetPassword/',
  verifyOtp: 'admin/verifyOTP',
  resendOtp: 'admin/resendOtp',
  // dashboard
  dashboard: 'admin/mainDashBoard',

  // subadmin 
  subAdminList: 'admin/subAdminList',
  subAdminAdd: 'admin/createSubAdmin',
  subAdminDelete: 'admin/deleteSubAdmin',
  subAdminView: 'admin/subAdminView',
  subAdminEdit: 'admin/updateSubAdmin',
  subAdminBlockUnblock: 'admin/blockUnblockSubAdmin',

  // user
  userList: 'admin/listUser',
  viewUser: 'admin/viewUser',
  blockUnblockUser: 'admin/blockUnblockUser',

  // user/customer
  userListCustomer: 'admin/listConsumer',
  viewCustomer: 'admin/viewConsumer',
  blockUnblockCustomer: 'admin/blockUnblockConsumer',
  deleteUser: 'admin/deleteConsumer',
  consumerOrdersList: '/admin/consumerOrdersList',

  //user/retailer
  userListRetailer: 'admin/listRetailer',
  viewRetailer: 'admin/viewRetailer',
  blockUnblockRetailer: 'admin/blockUnblockRetailer',
  deleteRetailer: 'admin/deleteRetailer',
  approvedRetailer: 'admin/approveRetailer',
  //user/influencer
  userListInfluencer: 'admin/influencerList',
  userListWithoutPagination: 'admin/influencerListwithoutPaginate',
  viewInfluencer: 'admin/viewInfluencer',
  blockUnblockInfluencer: 'admin/blockUnblockInfluencer',
  deleteInfluencer: 'admin/deleteInfluencer',
  approveInfluencer: 'admin/approveInfluencer',
  rejectInfluencer: '/admin/rejectInfluencer',

  // category
  categoryList: 'admin/categoryList',
  categoryListWithoutPagination: 'product/listCategoryWithoutPagination',
  addCategory: 'admin/addCategory',
  viewCategory: 'admin/viewCategory',
  editCategory: 'admin/editCategory',
  deleteCategory: 'admin/deleteCategory',

  // sub category
  subCategoryList: 'admin/subCategoryList',
  subCategoryListWithoutPagination: 'product/listSubCategoryWithoutPagination',
  subAddCategory: 'admin/addSubCategory',
  subViewCategory: 'admin/viewSubCategory',
  subEditCategory: 'admin/editSubCategory',
  subDeleteCategory: 'admin/deleteSubCategory',

  // swatches
  swatchesList: 'admin/listSwatches',
  swatchesListWithoutPagination: 'product/listSwatchesWithoutPagination',
  swatchesView: 'admin/viewSwatches',
  swatchesAdd: 'admin/addSwatches',
  swatchesedit: 'admin/editSwatches',
  swatchesDelete: 'admin/deleteSwatches',

  // embellishment 
  embellishmentList: 'admin/listEmbellishment',
  listEmbellishmentWithoutpagination: 'admin/listEmbellishmentWithoutpagination',
  embellishmentAdd: 'admin/addEmbellishment',
  embellishmentView: 'admin/viewEmbellishment',
  embellishmentEdit: 'admin/editEmbellishment',
  embellishmentDelete: 'admin/deleteEmbellishment',

  // skirt
  skirtList: 'product/listSkirt',
  skirtListWithPagination: 'product/listSkirtWithPagination',
  skirtView: 'product/viewSkirt',
  skirtAdd: 'product/addSkirt',
  skirtEdit: 'product/editSkirt',

  //arm
  armAdd: 'product/addArm',
  armList: 'product/listArmWithPagination',
  editArm: 'product/editArm',
  viewArm: 'product/viewArm',


  //appointmnent
  appointmentList: 'admin/listAppointment',
  appointmentView: 'admin/viewAppointment',
  appointmentEdit: 'admin/editAppointment',
  appointmentDelete: 'admin/deleteAppointment',

  //coupon
  couponList: 'coupon/listCoupon',
  couponView: 'coupon/viewCoupon',
  couponAdd: 'coupon/createInfCoupon',
  couponEdit: 'coupon/editCoupon',
  couponDelete: 'coupon/deleteCoupon',


  // product as
  listProduct: 'product/listProduct',
  addProduct: 'product/addProduct',
  viewProduct: 'product/viewProduct',
  editProduct: 'product/editProduct',
  deleteProduct: 'product/deleteProduct',

  // 
  contactUsList: 'admin/listContactUs',
  viewContactUs: 'admin/contactUs',
  deleteContactUs: 'admin/contactUs',

  // transaction
  transactionList: 'admin/listTransaction',
  viewTransacion: 'admin/transaction',

  // earn coin
  earnCoinList: 'admin/listEarncoin',
  addEarnCoin: 'admin/earncoin',
  viewEarnCoin: 'admin/earncoin',
  editEarnCoin: 'admin/earncoin',
  deleteEarnCoin: 'admin/earncoin',

  // genie coin
  genieCoinList: 'admin/listGenieCoin',
  addGenieCoin: 'admin/genieCoin',
  viewGenieCoin: 'admin/genieCoin',
  editGenieCoin: 'admin/genieCoin',
  deleteGenieCoin: 'admin/genieCoin',

  // static content
  staticContentList: 'static/staticContentList',
  addStaticContent: 'static/listStaticContent',
  viewStaticContent: 'static/viewStaticContent',
  editStaticContent: 'static/editStaticContent',
  deleteStaticContent: 'static/staticContent',
  homepageView: 'static/viewHomePage',
  homepageEdit: 'static/editHomePage',
  logoView: 'static/viewLogo',
  logoEdit: 'static/editLogo',

  //faq management
  listFaq: 'static/faqList',
  viewFaq: 'static/viewFAQ',
  deleteFaq: 'static/deleteFAQ',
  addFaq: 'static/addFAQ',
  editFaq: 'static/editFAQ',

  // setting 
  changePassword: 'admin/changePassword',
  profile: 'admin/viewProfile',
  // editProfile: 'admin/updateAdminProfile',
  editProfile: 'admin/editProfile',
  uploadFile: 'product/uploadFile',

  //color management
  colorList: 'admin/listColorWithPagination',
  colorListWithoutpagination: 'product/listColorWithoutpagination',
  addNewColor: 'admin/addColor',
  viewColor: 'admin/viewColor',
  editColor: 'admin/editColor',
  deleteColor: 'admin/deleteColor',
  colorsList: 'admin/listColor', //

  // premium color management
  listPremiumColorWithPagination: 'admin/listPremiumColorWithPagination',
  listPremiumColor: 'admin/listPremiumColor',
  addPremiumColor: 'admin/addPremiumColor',
  viewPremiumColor: 'admin/viewPremiumColor',
  editPremiumColor: 'admin/editPremiumColor',
  deletePremiumColor: 'admin/deletePremiumColor',
  // colorsList: 'admin/listColor',

  //visitor management
  viewVisitor: 'admin/viewVisitors',
  listVisitor: 'admin/listVistors',
  deleteVisitor: 'admin/deleteVisitor',

  //silhouette and neckline
  dressTypeList: 'product/dressTypeList',
  listSilhouette: 'product/dressTypeList',
  editSilhouette: 'product/editDressType',
  viewSilhouette: 'product/viewDressType',
  deleteSilhouette: 'product/deleteDressType',
  addSilhouette: 'product/addDressType',

  //trunk and trade
  listTrunkTrade: 'admin/listShowsWithPagination',
  editTrunkTrade: 'admin/editShows',
  viewTrunkTrade: 'admin/viewShows',
  deleteTrunkTrade: 'admin/deleteShows',
  addTrunkTrade: 'admin/addShows',

  //blog press story
  listBlogPress: 'story/blogList',
  viewBlogPress: 'story/viewBlog',
  deleteBlogPress: 'story/deleteBlog',
  editBlogPress: 'story/editBlog',
  addBlogPress: 'story/addBlog',

  //real brides
  listBride: 'story/bridesListWithPaginate',
  addBride: 'story/addBrides',
  viewBrides: 'story/viewBrides',
  deleteBrides: 'story/deleteBrides',
  editBrides: 'story/editBrides',

  // country state 
  countryList: 'product/countryList',
  stateList: 'product/stateList',

  //price management 
  listPrice: 'admin/listPriceMasterWithPagination',
  viewPrice: 'admin/viewPriceMaster',
  deletePrice: 'admin/deletePriceMaster',
  addPrice: 'admin/addPriceMaster',
  editPrice: 'admin/editPriceMaster',

  // custom product
  listBodiceWithoutPagination: "product/listBodice",
  listSkirtWithoutPagination: "product/listSkirt",
  createCombination: "product/createCombination",
  listCombination: 'product/listCombination',
  deleteCombination: 'product/deleteCombination',
  viewCombination: 'product/viewCombination',
  editCombination: 'product/editCombination',

  //body management
  listBody: 'product/listBodiceWithPagination',
  viewBody: 'product/viewBodice',
  addBody: 'product/addBody',
  editBody: 'product/editBody',

  //top header
  viewHeaderLink: 'static/viewHeaderLink',
  editHeaderLink: 'static/editHeaderLink',

  //mask managemnt
  addMask: 'product/addMask',
  listMask: 'product/listMask',
  viewMask: 'product/viewMask',
  editMask: 'product/editMask',
  deleteMask: 'product/deleteMask',

  // collection category
  listCollection: 'product/listCollection',
  categoryWithSubCategorylist: 'product/categoryWithSubCategorylist',
  editCollection: 'product/editCollection',

  //product category
  productCategoryList: 'product/listNewProduct',
  newProductWithPagination: 'product/newProductWithPagination',
  deleteNewProduct: 'product/deleteNewProduct',
  viewNewProduct: 'product/viewNewProduct',
  editNewProduct: 'product/editNewProduct',

  //product collection
  productViewCollection: 'product/viewCollection',

  // diamond management
  listDiamond: 'product/listDiamond',
  listDiamondWithPaginate: 'product/listDiamondWithPaginate',
  createDiamond: 'product/createDiamond',
  viewDiamond: 'product/viewDiamond',
  editDiamond: 'product/editDiamond',
  deleteDiamond: 'product/deleteDiamond',

  // tax management
  viewTax: 'product/viewTax',
  updateTax: 'product/updateTax',
  viewShippingCharge: 'product/viewShippingCharge',
  updateShippingCharge: 'product/updateShippingCharge',
  addVat: 'user/addVat',
  listVat: 'user/vatList',
  updateVat: 'user/updateVat',
  viewVat: 'user/viewVat',

  // new addCustomProductApi
  addOrUpdateNewCustomProduct: 'product/addOrUpdateNewCustomProduct',
  getAllCustomProductWithPagination: 'product/getAllCustomProductWithPagination',
  viewNewCustomProduct: 'product/viewNewCustomProduct',
  deleteCustomProduct: 'product/deleteCustomProduct',
  //sketch managemnt 
  getSketchDress : 'product/getSketchDress',
  addOrUpdateSketchDress : 'product/addOrUpdateSketchDress',

}








