import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddTaxShippingComponent } from './add-tax-shipping/add-tax-shipping.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { VatCountriesListComponent } from './vat-countries-list/vat-countries-list.component';
import { EditVatComponent } from './edit-vat/edit-vat.component';

const routes: Routes = [
  { path: 'tax-shipping', component: AddTaxShippingComponent },
  { path: 'vat-list', component: VatCountriesListComponent },
  { path: 'edit-Vat', component: EditVatComponent }
]

@NgModule({
  declarations: [AddTaxShippingComponent, VatCountriesListComponent, EditVatComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule]
})
export class TaxShippingManagementModule { }
