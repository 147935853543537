import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddArmComponent } from './add-arm/add-arm.component';
import { EditArmComponent } from './edit-arm/edit-arm.component';
import { ViewArmComponent } from './view-arm/view-arm.component';
import { ListArmComponent } from './list-arm/list-arm.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';

const routes:Routes=[
  {path:'add-arm',component:AddArmComponent},
  {path:'edit-arm',component:EditArmComponent},
  {path:'view-arm',component:ViewArmComponent},
  {path:'list-arm',component:ListArmComponent},
  {path:'',component:ListArmComponent}
]

@NgModule({
  declarations: [AddArmComponent, EditArmComponent, ViewArmComponent, ListArmComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ]
})
export class ArmManagementModule { }
